import ReactDOM from "react-dom";
import Backdrop from "shared/components/portals/Backdrop";
import portalStyles from "shared/styles/Portal.module.scss";
import styles from "./OptionSelectorPortal.module.scss";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface PortalProps {
  title: string;
  options: OptionItem[];
  handleAccept: () => void;
  handleCancel: () => void;
}

export interface OptionItem {
  id: number;
  title: string;
  onClick?: () => void;
  navigationPath?: string;
}
const OptionSelectorPortal = ({
  title,
  options,
  handleAccept,
  handleCancel,
}: PortalProps) => {
  const navigate = useNavigate();

  const handleClick = (item: OptionItem) => {
    if (item.navigationPath) {
      navigate(item.navigationPath);
    }
  };
  return (
    <>
      <Backdrop></Backdrop>,
      {ReactDOM.createPortal(
        <div className={portalStyles.portal_content}>
          <h3>{title}</h3>
          <div className={styles.options_container}>
            {options.map((i) => (
              <Button
                key={i.id + ""}
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => handleClick(i)}
              >
                {i.title}
              </Button>
            ))}
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleCancel}
            >
              Cancelar
            </Button>
          </div>
        </div>,
        document.getElementById("overlay-root") as HTMLElement
      )}
    </>
  );
};

export default OptionSelectorPortal;
