import { useAppSelector } from "store/hooks";
import { useEffect, useState } from "react";
import { RootState } from "store";
import useActions from "locations/store/useActions";
import { Location } from "locations/types";
import BackdropPortal from "shared/components/portals/Backdrop";
import ReactDOM from "react-dom";
import portalStyles from "shared/styles/Portal.module.scss";
import sharedStyles from "shared/styles/Form.module.scss";
import { Identity } from "shared/types/identity";
import { Product } from "inventory/product/types";
import { Button } from "@mui/material";
import MainList from "shared/components/main-list/MainList";
import { ProductFooter } from "shared/components/access-item/access-item-templates/Footers";
import { COMPONENTS } from "configuration/types";
import { IAccessItem } from "shared/components/access-item/AccessItem";

const ProductsNotInLocationListPage = ({
  handleAdd,
  handleCancel,
}: PortalProps) => {
  const location = useAppSelector<Location | null>(
    (state: RootState) => state.locations.location
  );
  const loading = useAppSelector<boolean>(
    (state: RootState) => state.locations.loadingLocation
  );

  const { fetchProductsNotInLocation } = useActions();

  const [selectedItems, setSelectedItems] = useState<Product[]>([]);

  useEffect(() => {
    if (location) {
      fetchProductsNotInLocation(location);
    }
  }, []);

  const onSelectionChanged = (items: Identity[] | null) => {
    if (items) {
      setSelectedItems(items as Product[]);
    } else {
      setSelectedItems([]);
    }
  };

  const onAdd = () => {
    handleAdd(selectedItems);
  };

  const fetchItemsInHierarchy = async (parentId?: string) => {
    if (location) {
      const products = (await fetchProductsNotInLocation(
        location,
        parentId
      )) as IAccessItem[];

      return products;
    }
    return [];
  };

  const onSearch = (term: string, data: any) => {
    const searchTerm = term.toUpperCase();
    const result = data?.filter((item: any) => {
      if (item.description?.includes(searchTerm)) {
        return item;
      }
    });

    return result;
  };

  return (
    <>
      <MainList
        data={location?.productsNotInLocation ?? []}
        loading={loading}
        itemImagePath="imageUrl"
        itemTitlePath="description"
        selectionMode="multiple"
        onItemClick={() => {}}
        gridViewFooter={ProductFooter}
        onSelectionChange={onSelectionChanged}
        onFetchDataInHierarchy={fetchItemsInHierarchy}
        onSearch={onSearch}
        controlOwnerName={COMPONENTS.LOCATIONS + "_PRODUCTS_NOT_IN_LOCATION"}
      ></MainList>

      <div className={sharedStyles.buttons_bar}>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={handleCancel}
        >
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          onClick={onAdd}
        >
          Agregar
        </Button>
      </div>
    </>
  );
};

interface PortalProps {
  handleAdd: (objs: Product[]) => void;
  handleCancel: () => void;
}

const ProductsNotInLocationPagePortal = ({
  handleAdd,
  handleCancel,
}: PortalProps) => {
  return (
    <>
      <BackdropPortal>
        {ReactDOM.createPortal(
          <div className={portalStyles.portal_content}>
            <ProductsNotInLocationListPage
              handleAdd={handleAdd}
              handleCancel={handleCancel}
            ></ProductsNotInLocationListPage>
          </div>,
          document.getElementById("overlay-root") as HTMLElement
        )}
      </BackdropPortal>
    </>
  );
};

export default ProductsNotInLocationPagePortal;
