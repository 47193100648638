import styles from "./LoginPage.module.scss";
import LoginForm from "../components/LoginForm";
import { Login } from "../types";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { actions as notificationsActions } from "../../../notifications/store";
import {
  HORIZONTAL_POSITIONS,
  VERTICAL_POSITIONS,
} from "../../../notifications/types";
import PositionedSnackbar from "../../../notifications/snackbar/Snackbar";
import { RootState } from "../../../store";
import useAuthActions from "../../hooks/useAuthActions";

const LoginPage = () => {
  const { login } = useAuthActions();

  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(
    (state: RootState) => state.auth.loadingUser
  );
  const notification = useAppSelector(
    (state) => state.notifications.notification
  );

  const onLogin = async (obj: Login) => {
    try {
      await login(obj);
    } catch (error: any) {
      const message = error.response?.data?.title || error.message;
      dispatch(
        notificationsActions.showNotification({
          message: message,
          type: "error",
          vertical: VERTICAL_POSITIONS.BOTTOM,
          horizontal: HORIZONTAL_POSITIONS.CENTER,
        })
      );
    }
  };
  return (
    <div className={styles.main_content}>
      <LoginForm handleSubmit={onLogin} isLoading={isLoading}></LoginForm>
      {notification && (
        <PositionedSnackbar
          {...{ ...notification, open: !!notification }}
        ></PositionedSnackbar>
      )}
    </div>
  );
};

export default LoginPage;
