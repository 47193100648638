import styles from "./ImagePresenter.module.scss";
import { useEffect, useRef, useState } from "react";
import CroppingPortal from "./cropping-modal/CroppingModal";

export interface ImagePresenterProps {
  onChange: (e: File | null) => void;
  imageUrl: string | null;
}

const ImagePresenter = ({ onChange, imageUrl }: ImagePresenterProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<File | null>();
  const [preview, setPreview] = useState<string | null>();
  const [cropping, setCropping] = useState<boolean>(false);

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onPreviewChange(reader.result as string);
      };
      reader.readAsDataURL(image);
    } else {
      onPreviewChange(null);
    }
  }, [image]);

  const onPreviewChange = (value: string | null) => {
    setPreview(value);
    onChange(image ?? null);
  };

  const handleCroppedImage = (image: File | null) => {
    if (image) {
      setImage(image);
    }
    setCropping(false);
  };

  const handleCancelCropp = () => {
    setCropping(false);
  };

  return cropping && preview ? (
    <CroppingPortal
      imagePreview={preview!}
      onCropped={handleCroppedImage}
      onCancel={handleCancelCropp}
    ></CroppingPortal>
  ) : (
    <div className={styles.img_presenter}>
      {preview || imageUrl ? (
        <img
          src={preview || imageUrl!}
          onClick={(event) => {
            event.preventDefault();
            fileInputRef.current?.click();
          }}
        ></img>
      ) : (
        <button
          onClick={(event) => {
            event.preventDefault();
            fileInputRef.current?.click();
          }}
        >
          No Image
        </button>
      )}
      <input
        type="file"
        style={{ display: "none" }}
        accept="image/*"
        ref={fileInputRef}
        onChange={(event) => {
          const file = event.target.files?.item(0);
          if (file && file.type.substring(0, 5) === "image") {
            setPreview(null);
            setImage(file);
            setCropping(true);
          } else {
            setImage(null);
          }
        }}
      ></input>
    </div>
  );
};

export default ImagePresenter;
