export interface Notification {
  status: NOTIFICATION_STATUS;
  title: string;
  message: string;
  vertical: VERTICAL_POSITIONS;
  horizontal: HORIZONTAL_POSITIONS;
}

export enum NOTIFICATION_STATUS {
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
}

export enum VERTICAL_POSITIONS {
  TOP = "top",
  BOTTOM = "bottom",
}

export enum HORIZONTAL_POSITIONS {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}
