import PositionedSnackbar from "notifications/snackbar/Snackbar";
import styles from "shared/styles/Page.module.scss";
import { useAppSelector } from "store/hooks";
import { ProductLocationTx } from "inventory/product-location-tx/types";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { RootState } from "store";
import ProductLocationTxForm from "inventory/product-location-tx/components/form/ProductLocationTxForm";
import useActions from "inventory/product-location-tx/store/useActions";
import { useActions as useLocationActions } from "locations/store/useActions";
import { Location } from "locations/types";
import useAuthUser from "auth/hooks/useAuthUser";
import dayjs from "dayjs";
import { useActiveLocations } from "locations/store/useSelectors";

const operationTypes = [
  {
    id: 1,
    name: "ENTRADA DE PRODUCTO",
  },
  {
    id: 2,
    name: "SALIDA DE PRODUCTO",
  },
  {
    id: 3,
    name: "VENTA DE PRODUCTO",
  },
];

const ProductLocationTxPage = () => {
  const user = useAuthUser();

  const params = useParams();
  const formData = useAppSelector<ProductLocationTx | null>(
    (state: RootState) => state.productLocationTxs.productLocationTx
  );
  const loading = useAppSelector(
    (state: RootState) => state.productLocationTxs.loadingProductLocationTx
  );
  const notification = useAppSelector(
    (state) => state.notifications.notification
  );

  // const locations = useAppSelector<Location[] | null>((state) =>
  //   state.locations.locations?.filter((l: Location) => l.isActive === true)
  // );

  const locations = useActiveLocations().filter(
    (l) => l.id !== user?.currentLocation?.id
  );

  const {
    fetchProductLocationTxById,
    saveProductLocationTx,
    getNextReferenceCodeByLocationId,
  } = useActions();
  const { fetchProductsInLocation } = useLocationActions();

  useEffect(() => {
    const id = params.id;
    const operationType = params.operationType;
    if (id) {
      fetchProductLocationTxById(id);
    }
  }, []);

  useEffect(() => {
    if (formData?.involvedLocation) {
      if (!formData.involvedLocation.products) {
        fetchProductsInLocation(formData.involvedLocation);
      }
    }
  }, [formData?.involvedLocation]);

  const navigate = useNavigate();

  const [newReferenceCode, setNewReferenceCode] = useState<string>("");

  const onSave = (obj: ProductLocationTx) => {
    const objToSave = {
      ...obj,
      txDate: dayjs(obj.txDate).startOf("day").toDate(),
    };
    saveProductLocationTx(objToSave).then(() => {
      navigate("/product-location-txs");
    });
  };

  const onInvolvedLocationChanged = async (newLocation: Location | null) => {
    const id = params.id;
    if (newLocation && !id) {
      fetchProductsInLocation(newLocation);
      if (user?.currentLocation?.id) {
        const code = await getNextReferenceCodeByLocationId(
          user.currentLocation.id,
          newLocation.id!
        );
        setNewReferenceCode(code ?? "");
      } else {
        setNewReferenceCode("");
      }
    } else {
      setNewReferenceCode("");
    }
  };

  return (
    <>
      <div className={styles.page_content}>
        <ProductLocationTxForm
          formData={formData}
          handleSubmit={onSave}
          loading={loading}
          locations={locations}
          operationTypes={operationTypes}
          predefinedOperationType={
            params?.operationType ? Number(params.operationType) : undefined
          }
          handleCancel={() => {
            navigate("/product-location-txs");
          }}
          onInvolvedLocationChange={onInvolvedLocationChanged}
          newReferenceCode={newReferenceCode}
        ></ProductLocationTxForm>
        {notification && (
          <PositionedSnackbar
            {...{ ...notification, open: !!notification }}
          ></PositionedSnackbar>
        )}
      </div>
    </>
  );
};

export default ProductLocationTxPage;
