import { useFormik } from "formik";
import sharedStyles from "shared/styles/Form.module.scss";
import portalStyles from "shared/styles/Portal.module.scss";
import { Button, FormControlLabel, Switch } from "@mui/material";
import Backdrop from "shared/components/portals/Backdrop";
import ReactDOM from "react-dom";
import { ChildProductPropertiesToUpdate } from "inventory/product/types";

interface PortalProps {
  handleAccept: (values: ChildProductPropertiesToUpdate) => void;
  handleCancel: () => void;
}

const ProductPropertiesSelectorPortal = ({
  handleAccept,
  handleCancel,
}: PortalProps) => {
  const formik = useFormik<ChildProductPropertiesToUpdate>({
    initialValues: {
      productCategoryId: false,
      brandId: false,
      referenceCode: false,
      description: false,
      cost: false,
      minSellPrice: false,
      imageUrl: false,
      isActive: false,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      handleAccept(values);
    },
  });

  return (
    <>
      <Backdrop></Backdrop>,
      {ReactDOM.createPortal(
        <div className={portalStyles.portal_content}>
          <h3>Selecione las propiedades a actualizar en los hijos</h3>
          <div className={sharedStyles.form_content}>
            <form onSubmit={formik.handleSubmit}>
              <div className={sharedStyles.form_section_row}>
                <div className={sharedStyles.form_row}>
                  <FormControlLabel
                    className={sharedStyles.input_field}
                    control={
                      <Switch
                        id="productCategoryId"
                        name="productCategoryId"
                        checked={formik.values.productCategoryId}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Categoría"
                    labelPlacement="top"
                  />
                </div>
                <div className={sharedStyles.form_row}>
                  <FormControlLabel
                    className={sharedStyles.input_field}
                    control={
                      <Switch
                        id="brandId"
                        name="brandId"
                        checked={formik.values.brandId}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Marca"
                    labelPlacement="top"
                  />
                </div>
                <div className={sharedStyles.form_row}>
                  <FormControlLabel
                    className={sharedStyles.input_field}
                    control={
                      <Switch
                        id="referenceCode"
                        name="referenceCode"
                        checked={formik.values.referenceCode}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Código"
                    labelPlacement="top"
                  />
                </div>
              </div>
              <div className={sharedStyles.form_section_row}>
                <div className={sharedStyles.form_row}>
                  <FormControlLabel
                    className={sharedStyles.input_field}
                    control={
                      <Switch
                        id="description"
                        name="description"
                        checked={formik.values.description}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Descripción"
                    labelPlacement="top"
                  />
                </div>
                <div className={sharedStyles.form_row}>
                  <FormControlLabel
                    className={sharedStyles.input_field}
                    control={
                      <Switch
                        id="cost"
                        name="cost"
                        checked={formik.values.cost}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Costo"
                    labelPlacement="top"
                  />
                </div>
                <div className={sharedStyles.form_row}>
                  <FormControlLabel
                    className={sharedStyles.input_field}
                    control={
                      <Switch
                        id="minSellPrice"
                        name="minSellPrice"
                        checked={formik.values.minSellPrice}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Precio mínimo"
                    labelPlacement="top"
                  />
                </div>
              </div>
              <div className={sharedStyles.form_section_row}>
                <div className={sharedStyles.form_row}>
                  <FormControlLabel
                    className={sharedStyles.input_field}
                    control={
                      <Switch
                        id="imageUrl"
                        name="imageUrl"
                        checked={formik.values.imageUrl}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Imagen"
                    labelPlacement="top"
                  />
                </div>
                <div className={sharedStyles.form_row}>
                  <FormControlLabel
                    className={sharedStyles.input_field}
                    control={
                      <Switch
                        id="isActive"
                        name="isActive"
                        checked={formik.values.isActive}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Activo"
                    labelPlacement="top"
                  />
                </div>
                <div className={sharedStyles.form_row}></div>
              </div>
              <div className={sharedStyles.buttons_bar}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={handleCancel}
                >
                  Cancelar
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Guardar
                </Button>
              </div>
            </form>
          </div>
          ,
        </div>,
        document.getElementById("overlay-root") as HTMLElement
      )}
    </>
  );
};
export default ProductPropertiesSelectorPortal;
