import PositionedSnackbar from "../../../../notifications/snackbar/Snackbar";
import styles from "../../../../shared/styles/Page.module.scss";
import ProductCategoryForm from "../../componentes/form/ProductCategoryForm";
import { useAppSelector } from "../../../../store/hooks";
import { ProductCategory } from "../../types";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { RootState } from "../../../../store";
import useProductCategoryActions from "../../store/useActions";

const ProductCategoryPage = () => {
  const params = useParams();
  const formData = useAppSelector(
    (state: RootState) => state.productCategories.productCategory
  );
  const loading = useAppSelector(
    (state: RootState) => state.productCategories.loadingProductCategory
  );
  const notification = useAppSelector(
    (state) => state.notifications.notification
  );

  const { fetchProductCategoryById, saveProductCategory } =
    useProductCategoryActions();

  useEffect(() => {
    const id = params.id;
    if (id) {
      fetchProductCategoryById(id);
    }
  }, []);

  const navigate = useNavigate();

  const onSave = (obj: ProductCategory, image: File | null) => {
    saveProductCategory(obj, image).then(() => {
      navigate("/product-categories");
    });
  };
  return (
    <div className={styles.page_content}>
      <ProductCategoryForm
        formData={formData}
        handleSubmit={onSave}
        loading={loading}
        handleCancel={() => {
          navigate("/product-categories");
        }}
      ></ProductCategoryForm>
      {notification && (
        <PositionedSnackbar
          {...{ ...notification, open: !!notification }}
        ></PositionedSnackbar>
      )}
    </div>
  );
};

export default ProductCategoryPage;
