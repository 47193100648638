import PositionedSnackbar from "notifications/snackbar/Snackbar";
import styles from "shared/styles/Page.module.scss";
import { useAppSelector } from "store/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { RootState } from "store";
import useActions from "inventory/product/store/useActions";
import useAuthUser from "auth/hooks/useAuthUser";
import {
  ChildProductPropertiesToUpdate,
  Product,
} from "inventory/product/types";
import ProductForm from "inventory/product/components/form/ProductForm";
import { Brand } from "inventory/brand/types";
import { ProductCategory } from "inventory/product-category/types";
import useBrandActions from "inventory/brand/store/useActions";
import useProductCategorydActions from "inventory/product-category/store/useActions";
import ConfirmPortal from "shared/portals/confirm/ConfirmPortal";
import ProductPropertiesSelectorPortal from "inventory/product/components/product-properties-selector/ProductPropertiesSelectorPortal";

const ProductPage = () => {
  const params = useParams();
  const formData = useAppSelector<Product | null>(
    (state: RootState) => state.products.product
  );
  const loading = useAppSelector<boolean>(
    (state: RootState) => state.products.loadingProduct
  );
  const notification = useAppSelector(
    (state) => state.notifications.notification
  );

  const productCategories = useAppSelector<ProductCategory[] | null>(
    (state) => state.productCategories.productCategories
  );
  const brands = useAppSelector<Brand[] | null>((state) => state.brands.brands);

  const {
    fetchProductById,
    saveProduct,
    updateProductAndChildren,
    createProductFromParent,
  } = useActions();
  const { fetchBrands } = useBrandActions();
  const { fetchProductCategories } = useProductCategorydActions();

  const user = useAuthUser();

  useEffect(() => {
    fetchProductCategories();
    fetchBrands();
    const id = params.id;

    if (id) {
      fetchProductById(id);
    }
  }, [params.id]);

  const navigate = useNavigate();
  const [showConfirmPortal, setShowConfirmPortal] = useState<boolean>(false);
  const [showUpdateChildrenConfirmPortal, setShowUpdateChildrenConfirmPortal] =
    useState<boolean>(false);

  const [tempImage, setTempImage] = useState<File | null>(null);
  const [tempProductToSave, setTempProductToSave] = useState<Product | null>(
    null
  );

  const onSave = (obj: Product, image: File | null) => {
    if (obj.children.length > 0) {
      setTempProductToSave(obj);
      setTempImage(image);
      setShowUpdateChildrenConfirmPortal(true);
      return;
    }
    saveProduct(obj, image);
  };

  const onUpdateProductAndChildren = (
    obj: Product,
    image: File | null,
    childrenProps: ChildProductPropertiesToUpdate
  ) => {
    updateProductAndChildren(obj, image, childrenProps);
  };

  const onCreateFromParent = () => {
    if (formData?.id) {
      createProductFromParent(formData?.id).then((newProductId) => {
        if (newProductId) {
          // fetchProductById(newProductId);
          navigate(`/products/${newProductId}`);
        }
      });
    } else {
      alert("Producto no disponible");
    }
  };

  return (
    <>
      {showConfirmPortal && (
        <ConfirmPortal
          title={`Está seguro que desea crear un producto hijo a partir de ${formData?.description}?`}
          handleAccept={() => {
            setShowConfirmPortal(false);
            onCreateFromParent();
          }}
          handleCancel={() => setShowConfirmPortal(false)}
        ></ConfirmPortal>
      )}

      {showUpdateChildrenConfirmPortal && (
        <ProductPropertiesSelectorPortal
          handleAccept={(value: ChildProductPropertiesToUpdate) => {
            setShowUpdateChildrenConfirmPortal(false);
            onUpdateProductAndChildren(tempProductToSave!, tempImage, value);
            setTempProductToSave(null);
            setTempImage(null);
          }}
          handleCancel={() => setShowUpdateChildrenConfirmPortal(false)}
        ></ProductPropertiesSelectorPortal>
      )}

      <div className={styles.page_content}>
        <ProductForm
          formData={formData}
          handleSubmit={onSave}
          loading={loading}
          categories={productCategories}
          brands={brands}
          handleCancel={() => {
            navigate("/products");
          }}
          handleCreateFromProduct={() => {
            setShowConfirmPortal(true);
          }}
        ></ProductForm>
        {notification && (
          <PositionedSnackbar
            {...{ ...notification, open: !!notification }}
          ></PositionedSnackbar>
        )}
      </div>
    </>
  );
};

export default ProductPage;
