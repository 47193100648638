import { Tab } from "@mui/material";

export interface LinkTabProps {
  label: string;
  href: string;
  disabled?: boolean;
}

const LinkTab = (props: LinkTabProps) => {
  return (
    <Tab
      style={{ fontSize: "large", fontWeight: "bold" }}
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
};

export default LinkTab;
