import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "devextreme/dist/css/dx.material.lime.light.compact.css";
import AppRoutes from "router/Routes";

(function initialize() {})();

const router = createBrowserRouter([
  {
    path: "/*",
    element: <AppRoutes></AppRoutes>,
  },
]);

function App() {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <RouterProvider router={router}></RouterProvider>
      </LocalizationProvider>
    </>
  );
}

export default App;
