import { useFormik } from "formik";
import sharedStyles from "shared/styles/Form.module.scss";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { Button } from "@mui/material";
import { StartEndDatesFilterSchema } from "./validations";

interface FormProps {
  handleSubmit: (startDate: Date, endDate: Date) => void;
}

const StartEndDatesFilter = ({ handleSubmit }: FormProps) => {
  const formik = useFormik<{
    startDate: dayjs.Dayjs | null;
    endDate: dayjs.Dayjs | null;
  }>({
    initialValues: {
      startDate: dayjs(),
      endDate: dayjs(),
    },
    validationSchema: StartEndDatesFilterSchema,
    onSubmit: (values) => {
      handleSubmit(
        values.startDate!.startOf("day").toDate(),
        values.endDate!.endOf("day").toDate()
      );
    },
  });
  return (
    <div className={sharedStyles.form_content}>
      <form onSubmit={formik.handleSubmit}>
        <div className={sharedStyles.form_section_row}>
          <div className={sharedStyles.form_row}>
            <DatePicker
              className={sharedStyles.input_field}
              label="Inicia"
              format="DD/MM/YYYY"
              value={formik.values.startDate}
              onChange={(e) => {
                formik.setFieldValue("startDate", e ?? null);
              }}
              slotProps={{
                textField: {
                  id: "txStartDateField",
                  name: "txStartDateField",
                  error:
                    formik.touched.startDate &&
                    Boolean(formik.errors.startDate),
                  helperText:
                    formik.touched.startDate && formik.errors.startDate,
                },
              }}
            ></DatePicker>
          </div>
          <div className={sharedStyles.form_row}>
            <DatePicker
              className={sharedStyles.input_field}
              label="Finaliza"
              format="DD/MM/YYYY"
              value={formik.values.endDate}
              onChange={(e) => {
                formik.setFieldValue("endDate", e ?? null);
              }}
              slotProps={{
                textField: {
                  id: "txEndDateField",
                  name: "txEndDateField",
                  error:
                    formik.touched.endDate && Boolean(formik.errors.endDate),
                  helperText: formik.touched.endDate && formik.errors.endDate,
                },
              }}
            ></DatePicker>
          </div>
          <div
            className={sharedStyles.buttons_bar}
            style={{ alignSelf: "self-end" }}
          >
            <Button color="primary" variant="contained" fullWidth type="submit">
              Filtrar
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default StartEndDatesFilter;
