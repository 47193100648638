import { useAppSelector } from "store/hooks";
import { useEffect, useState } from "react";
import { RootState } from "store";
import { useNavigate } from "react-router-dom";
import useActions from "inventory/product/store/useActions";
import useAuthUser from "auth/hooks/useAuthUser";
import { Identity } from "shared/types/identity";
import MainList from "shared/components/main-list/MainList";
import { ProductFooter } from "shared/components/access-item/access-item-templates/Footers";
import { Product } from "inventory/product/types";
import { COMPONENTS } from "configuration/types";

const ChildrenProductsListPage = () => {
  const navigate = useNavigate();
  const user = useAuthUser();

  const activeProduct = useAppSelector<Product | null>(
    (state: RootState) => state.products.product
  );
  const loading = useAppSelector(
    (state: RootState) => state.products.loadingProducts
  );

  const handleEdit = (obj: Identity) => {
    navigate(`/products/${obj.id!}`);
  };

  return (
    <>
      <MainList
        data={activeProduct?.children ?? []}
        loading={loading}
        itemImagePath="imageUrl"
        itemTitlePath="description"
        selectionMode="single"
        onItemClick={handleEdit}
        onAddClick={() => {}}
        gridViewFooter={ProductFooter}
        controlOwnerName={COMPONENTS.PRODUCTS + "_CHILDREN"}
      ></MainList>
    </>
  );
};

export default ChildrenProductsListPage;
