import { actions } from "reports/store/index";
import { actions as notificationsActions } from "notifications/store/index";
import useAxios from "shared/hooks/UseAxios";
import { useAppDispatch } from "store/hooks";

const BASE_URL = "reports";

const useActions = () => {
  const dispatch = useAppDispatch();
  const axios = useAxios();

  const fetchInOutProductsReportData = async (
    locationId: string,
    startDate: Date,
    endDate: Date
  ) => {
    dispatch(actions.loadReportStart());
    try {
      const response = await axios.get<any>(
        `${BASE_URL}/product-location-movement`,
        {
          params: {
            locationId,
            startDate,
            endDate,
          },
        }
      );

      dispatch(actions.loadReportSuccess(response.data));
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(actions.loadReportFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  return {
    fetchInOutProductsReportData,
  };
};

export { useActions };
export default useActions;
