import { useAppSelector } from "store/hooks";
import { useEffect, useState } from "react";
import { RootState } from "store";
import { useNavigate } from "react-router-dom";
import useActions from "inventory/product-location-tx/store/useActions";
import CustomDataGrid from "shared/components/custom-data-grid/CustomDataGrid";
import dayjs from "dayjs";
import useAuthUser from "auth/hooks/useAuthUser";
import { CustomDataGridColumn } from "shared/components/custom-data-grid/types";
import OptionSelectorPortal, {
  OptionItem,
} from "shared/portals/option-picker/OptionSelectorPortal";
import { Identity } from "shared/types/identity";

const columns: CustomDataGridColumn[] = [
  {
    field: "referenceCode",
    caption: "Factura",
  },
  {
    field: "txDate",
    caption: "Fecha",
    dataType: "date",
    format: "dd/MM/yyyy",
  },
  {
    field: "operationTypeId",
    caption: "Operación",
    cellCustomTextFn(cellData) {
      switch (cellData.value) {
        case 1:
          return "ENTRADA";
        case 2:
          return "DEVOLUCIÓN";
        case 3:
          return "VENTA";
        default:
          return cellData.valueText;
      }
    },
  },
  {
    field: "involvedLocation.name",
    caption: "Ubicación externa",
  },
  {
    field: "referencedTxId",
    caption: "Dependiente",
    cellCustomTextFn(cellData) {
      console.log(cellData);
      return !!cellData.value ? "Sí" : "No";
    },
  },
  {
    field: "createdOn",
    caption: "Registrado el",
    dataType: "date",
    format: "dd/MM/yyyy",
  },
];

const operationTypes: OptionItem[] = [
  {
    id: 1,
    title: "ENTRADA DE PRODUCTO",
    navigationPath: `create/1`,
  },
  {
    id: 2,
    title: "SALIDA DE PRODUCTO",
    navigationPath: `create/2`,
  },
  {
    id: 3,
    title: "VENTA DE PRODUCTO",
    navigationPath: `create/3`,
  },
];

const ProductLocationTxsListPage = () => {
  const navigate = useNavigate();
  const user = useAuthUser();

  const data = useAppSelector(
    (state: RootState) => state.productLocationTxs.productLocationTxs
  );
  const loading = useAppSelector(
    (state: RootState) => state.productLocationTxs.loadingProductLocationTxs
  );

  const {
    fetchProductLocationTxs,
    deleteProductLocationTxById,
    clearCurrentProductLocationTx,
  } = useActions();

  useEffect(() => {
    if (user?.currentLocation?.id) {
      fetchProductLocationTxs(user.currentLocation?.id);
    }
  }, [user]);

  const handleAdd = () => {
    clearCurrentProductLocationTx().then(() => {
      setShowOptionSelector(true);
    });
  };

  const handleEdit = (obj: Identity) => {
    navigate(obj.id!);
  };

  const handleDelete = (id: string) => {
    deleteProductLocationTxById(id).then(() => {
      if (user?.currentLocation?.id) {
        fetchProductLocationTxs(user.currentLocation?.id);
      }
    });
  };

  const [showOptionSelector, setShowOptionSelector] = useState(false);

  return (
    <>
      {showOptionSelector && (
        <OptionSelectorPortal
          title={"Selecciona una opción"}
          options={operationTypes}
          handleAccept={() => {}}
          handleCancel={() => setShowOptionSelector(false)}
        ></OptionSelectorPortal>
      )}
      <CustomDataGrid
        data={data}
        loading={loading}
        columns={columns}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onDelete={
          user?.email === "thegeox@gmail.com" ? handleDelete : undefined
        }
        allowExporting
        controlName={"product-location-txs"}
      ></CustomDataGrid>
    </>
  );
};

export default ProductLocationTxsListPage;
