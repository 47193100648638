import * as yup from "yup";

export const LocationSchema = yup.object({
  name: yup.string().trim().required("Nombre es requerido"),
  typeId: yup.string().trim().required("Tipo de ubicación es requerido"),
});

export const ProductLocationSchema = yup.object({
  quantity: yup.number().required("Cantidad es requerida"),
});
