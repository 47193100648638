import TabNavigator from "shared/navigation-tab/components/tab-navigator/TabNavigator";
import { LinkTabProps } from "shared/navigation-tab/components/link-tab/LinkTab";
import styles from "./TabsContainerPage.module.scss";

export interface pageProps {
  navTabs: LinkTabProps[];
}

const TabsContainerPage = ({ navTabs }: pageProps) => {
  return (
    <div className={styles.page_container}>
      <TabNavigator tabs={navTabs}></TabNavigator>
    </div>
  );
};

export default TabsContainerPage;
