import MainList from "../../../../shared/components/main-list/MainList";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { useEffect } from "react";
import { RootState } from "../../../../store";
import { IAccessItem } from "shared/components/access-item/AccessItem";
import { useNavigate } from "react-router-dom";
import useBrandActions from "../../store/useActions";
import { COMPONENTS } from "configuration/types";

const BrandsListPage = () => {
  const navigate = useNavigate();
  const brands = useAppSelector((state: RootState) => state.brands.brands);
  const loading = useAppSelector(
    (state: RootState) => state.brands.loadingBrands
  );

  const { fetchBrands, clearCurrentBrand } = useBrandActions();
  useEffect(() => {
    fetchBrands();
  }, []);

  const handleClick = (e: IAccessItem) => {
    navigate(e.id!);
  };

  const handleNew = () => {
    clearCurrentBrand().then(() => {
      navigate("create");
    });
  };

  const onSearch = (term: string, data: any) => {
    const searchTerm = term.toUpperCase();
    const result = data?.filter((item: any) => {
      if (item.name?.includes(searchTerm)) {
        return item;
      }
    });

    return result;
  };

  return (
    <>
      <MainList
        data={brands}
        onItemClick={handleClick}
        onAddClick={handleNew}
        loading={loading}
        onSearch={onSearch}
        controlOwnerName={COMPONENTS.BRANDS}
      ></MainList>
    </>
  );
};

export default BrandsListPage;
