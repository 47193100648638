import { createSlice } from "@reduxjs/toolkit";
import { ProductLocationTx } from "inventory/product-location-tx/types";

interface StateObject {
  productLocationTxs: ProductLocationTx[] | null;
  loadingProductLocationTxs: boolean;
  errorProductLocationTxs: string | null;
  productLocationTx: ProductLocationTx | null;
  loadingProductLocationTx: boolean;
  errorProductLocationTx: string | null;
}

const initialState: StateObject = {
  productLocationTxs: null,
  loadingProductLocationTxs: false,
  errorProductLocationTxs: null,
  productLocationTx: null,
  loadingProductLocationTx: false,
  errorProductLocationTx: null,
};

const productLocationTxsSlice = createSlice({
  name: "product-location-txs",
  initialState,
  reducers: {
    loadProductLocationTxsStart(state) {
      state.loadingProductLocationTxs = true;
      state.errorProductLocationTxs = null;
    },
    loadProductLocationTxsSuccess(state, action) {
      state.loadingProductLocationTxs = false;
      state.errorProductLocationTxs = null;
      state.productLocationTxs = action.payload;
    },
    loadProductLocationTxsFailure(state, action) {
      state.loadingProductLocationTxs = false;
      state.errorProductLocationTxs = action.payload;
    },
    loadProductLocationTxStart(state) {
      state.loadingProductLocationTx = true;
      state.errorProductLocationTx = null;
    },
    loadProductLocationTxSuccess(state, action) {
      state.loadingProductLocationTx = false;
      state.errorProductLocationTx = null;
      state.productLocationTx = action.payload;
    },
    loadProductLocationTxFailure(state, action) {
      state.loadingProductLocationTx = false;
      state.errorProductLocationTx = action.payload;
    },
    saveProductLocationTxStart(state) {
      state.loadingProductLocationTx = true;
    },
    saveProductLocationTxSuccess(state, action) {
      state.loadingProductLocationTx = false;
      state.productLocationTx = action.payload;
      state.errorProductLocationTx = null;
    },
    saveProductLocationTxFailure(state, action) {
      state.loadingProductLocationTx = false;
      state.productLocationTx = null;
      state.errorProductLocationTx = action.payload;
    },
    clearCurrentProductLocationTx(state) {
      state.productLocationTx = null;
      state.loadingProductLocationTx = false;
      state.errorProductLocationTx = null;
    },
    getNextReferenceCodeStart(state) {},
    getNextReferenceCodeSuccess(state, action) {},
    getNextReferenceCodeFailure(state, action) {},
  },
});

export const actions = productLocationTxsSlice.actions;
export default productLocationTxsSlice.reducer;
