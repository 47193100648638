import { useFormik } from "formik";
import sharedStyles from "shared/styles/Form.module.scss";
import portalStyles from "shared/styles/Portal.module.scss";
import { Button, TextField } from "@mui/material";
import { ProductLocation } from "locations/types";
import { ProductLocationSchema } from "locations/validations";
import BackdropPortal from "shared/components/portals/Backdrop";
import ReactDOM from "react-dom";
import ImagePresenter from "shared/components/image-presenter/ImagePresenter";

interface FormProps {
  handleSubmit: (entityObj: ProductLocation) => void;
  handleCancel: () => void;
  formData: ProductLocation | null;
}

const ProductLocationForm = ({
  handleSubmit,
  handleCancel,
  formData,
}: FormProps) => {
  const formik = useFormik<ProductLocation>({
    initialValues: formData ?? {
      id: null,
      productId: "",
      product: null,
      locationId: "",
      quantity: 0,
      isActive: false,
    },
    enableReinitialize: true,
    validationSchema: ProductLocationSchema,
    onSubmit: async (values) => {
      handleSubmit(values);
    },
  });

  return (
    <div className={sharedStyles.form_content}>
      <form onSubmit={formik.handleSubmit}>
        <ImagePresenter
          imageUrl={formik.values.product?.imageUrl ?? null}
          onChange={(e: File | null) => {}}
        ></ImagePresenter>
        <div className={sharedStyles.form_section_row}>
          <h3>Producto: {formik.values.product?.description}</h3>
        </div>
        <div className={sharedStyles.form_section_row}>
          <div className={sharedStyles.form_row}>
            <TextField
              type="number"
              id="quantity"
              name="quantity"
              label="Cantidad"
              autoComplete="no"
              inputProps={{ min: 0, step: "any" }}
              value={formik.values.quantity ?? ""}
              onChange={formik.handleChange}
              onFocus={(event) => {
                event.target.select();
              }}
              error={formik.touched.quantity && Boolean(formik.errors.quantity)}
              helperText={formik.touched.quantity && formik.errors.quantity}
              InputLabelProps={{ shrink: formik.values.quantity != null }}
            ></TextField>
          </div>
        </div>

        <div className={sharedStyles.buttons_bar}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleCancel}
          >
            Cancelar
          </Button>
          <Button color="primary" variant="contained" fullWidth type="submit">
            Guardar
          </Button>
        </div>
      </form>
    </div>
  );
};

const ProductLocationFormPortal = ({
  handleSubmit,
  handleCancel,
  formData,
}: FormProps) => {
  return (
    <>
      <BackdropPortal>
        {ReactDOM.createPortal(
          <div className={portalStyles.portal_content}>
            <ProductLocationForm
              handleSubmit={handleSubmit}
              handleCancel={handleCancel}
              formData={formData}
            ></ProductLocationForm>
          </div>,
          document.getElementById("overlay-root") as HTMLElement
        )}
      </BackdropPortal>
    </>
  );
};

export default ProductLocationFormPortal;
