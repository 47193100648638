import { createSlice } from "@reduxjs/toolkit";

interface StateObject {
  locationInOutProductsReportData: any;
  loadingReport: boolean;
  errorReport: string | null;
}

const initialState: StateObject = {
  locationInOutProductsReportData: null,
  loadingReport: false,
  errorReport: null,
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    loadReportStart(state) {
      state.loadingReport = true;
      state.errorReport = null;
    },
    loadReportSuccess(state, action) {
      state.loadingReport = false;
      state.errorReport = null;
      state.locationInOutProductsReportData = action.payload;
    },
    loadReportFailure(state, action) {
      state.loadingReport = false;
      state.errorReport = action.payload;
    },
  },
});

export const actions = reportsSlice.actions;
export default reportsSlice.reducer;
