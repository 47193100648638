import { createSlice } from "@reduxjs/toolkit";
import { Location } from "locations/types";

interface StateObject {
  locations: Location[] | null;
  loadingLocations: boolean;
  errorLocations: string | null;
  location: Location | null;
  loadingLocation: boolean;
  errorLocation: string | null;
  loadingProductsInLocation: boolean;
  errorProductsInLocation: string | null;
}

const initialState: StateObject = {
  locations: null,
  loadingLocations: false,
  errorLocations: null,
  location: null,
  loadingLocation: false,
  errorLocation: null,
  loadingProductsInLocation: false,
  errorProductsInLocation: null,
};

const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    loadLocationsStart(state) {
      state.loadingLocations = true;
      state.errorLocations = null;
    },
    loadLocationsSuccess(state, action) {
      state.loadingLocations = false;
      state.errorLocations = null;
      state.locations = action.payload;
    },
    loadLocationsFailure(state, action) {
      state.loadingLocations = false;
      state.errorLocations = action.payload;
    },
    loadLocationStart(state) {
      state.loadingLocation = true;
      state.errorLocation = null;
    },
    loadLocationSuccess(state, action) {
      state.loadingLocation = false;
      state.errorLocation = null;
      state.location = action.payload;
    },
    loadLocationFailure(state, action) {
      state.loadingLocation = false;
      state.errorLocation = action.payload;
    },
    saveLocationStart(state) {
      state.loadingLocation = true;
    },
    saveLocationSuccess(state, action) {
      state.loadingLocation = false;
      state.location = action.payload;
      state.errorLocation = null;
    },
    saveLocationFailure(state, action) {
      state.loadingLocation = false;
      state.location = null;
      state.errorLocation = action.payload;
    },
    clearCurrentLocation(state) {
      state.location = null;
      state.loadingLocation = false;
      state.errorLocation = null;
    },
    loadProductsInLocationStart(state) {
      state.loadingLocation = true;
    },
    loadProductsInLocationSuccess(state, action) {
      state.loadingLocation = false;
      state.locations = state.locations
        ? state.locations?.map((e) => {
            if (e.id === action.payload.id) {
              return action.payload;
            }
            return e;
          })
        : null;

      state.errorLocation = null;
    },
    loadProductsInLocationFailure(state, action) {
      state.loadingLocation = false;
      state.errorLocation = action.payload;
    },
    updateLocationFromStore(state, action) {
      state.loadingLocation = false;
      state.location =
        state.locations?.find((e) => e.id === action.payload) ?? null;
      state.errorLocation = null;
    },
    addProductsToLocationStart(state) {
      state.loadingProductsInLocation = true;
      state.errorProductsInLocation = null;
    },
    addProductsToLocationSuccess(state, action) {
      state.loadingProductsInLocation = false;
      state.errorProductsInLocation = null;
      if (state.location?.productsInLocation) {
        state.location.productsInLocation = [
          ...state.location?.productsInLocation,
          ...action.payload,
        ];
      }
    },
    addProductsToLocationFailure(state, action) {
      state.loadingProductsInLocation = false;
      state.errorProductsInLocation = action.payload;
    },
    updateProductLocationStart(state) {
      state.loadingProductsInLocation = true;
      state.errorProductsInLocation = null;
    },
    updateProductLocationSuccess(state, action) {
      state.loadingProductsInLocation = false;
      state.errorProductsInLocation = null;
      state.location?.productsInLocation?.map((e) => {
        if (e.id === action.payload.id) {
          return action.payload;
        }
        return e;
      });
    },
    updateProductLocationFailure(state, action) {
      state.loadingProductsInLocation = false;
      state.errorProductsInLocation = action.payload;
    },
  },
});

export const actions = locationsSlice.actions;
export default locationsSlice.reducer;
