import { Tabs } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./TabNavigator.module.scss";
import { useEffect, useState } from "react";
import LinkTab, { LinkTabProps } from "../link-tab/LinkTab";

interface TabNavigatorProps {
  tabs: LinkTabProps[];
}

const TabNavigator = ({ tabs }: TabNavigatorProps) => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const lastValue = pathSegments.at(-1);
    let index = tabs.findIndex((e: LinkTabProps) => e.href === lastValue);

    // THIS CODE IS USEFUL WHEN WE GOT A MULTI TAB PAGE
    if (index === -1) {
      for (let i = pathSegments.length - 2; i >= 0; i--) {
        index = tabs.findIndex((e: LinkTabProps) => e.href === pathSegments[i]);
        if (index !== -1) {
          break;
        }
      }
    }
    // ENDS MULTI TAB PAGE
    index = index >= 0 ? index : 0;
    setValue(index);
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigate(tabs[newValue].href);
  };

  return (
    <div className={styles.tab_container}>
      <Tabs
        value={value}
        onChange={handleChange}
        style={{ marginBottom: "10px" }}
        centered
      >
        {tabs.map((tab: LinkTabProps, index: number) => (
          <LinkTab
            key={index}
            label={tab.label}
            href={tab.href}
            disabled={tab.disabled}
          ></LinkTab>
        ))}
      </Tabs>
      <Outlet></Outlet>
    </div>
  );
};

export default TabNavigator;
