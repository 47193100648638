import GrantAccess from "auth/components/grant-access/GrantAccess";
import LoginPage from "auth/login/pages/LoginPage";
import UnauthorizedPage from "auth/pages/unauthorized/UnauthorizedPage";
import { COMPONENTS } from "configuration/types";
import ErrorPage from "error/ErrorPage";
import BrandPage from "inventory/brand/pages/form/BrandPage";
import BrandsListPage from "inventory/brand/pages/main-list/BrandsListPage";
import ProductCategoryPage from "inventory/product-category/pages/form/ProductCategoryPage";
import ProductCategoriesListPage from "inventory/product-category/pages/main-list/ProductCategoriesListPage";
import ProductLocationTxPage from "inventory/product-location-tx/pages/form/ProductLocationTxPage";
import ProductLocationTxsListPage from "inventory/product-location-tx/pages/main-list/ProductLocationTxsListPage";
import ChildrenProductsListPage from "inventory/product/pages/children-list/ChildrenProductsListPage";
import ProductPage from "inventory/product/pages/form/ProductPage";
import ProductsListPage from "inventory/product/pages/main-list/ProductsListPage";
import { ProductPageNavTabs } from "inventory/product/types";
import MainLayout from "layouts/main-layout/MainLayout";
import LocationPage from "locations/pages/form/LocationPage";
import LocationsListPage from "locations/pages/main-list/LocationsListPage";
import ProductsInLocationListPage from "locations/pages/products-in-location-list/ProductsInLocationListPage";
import { LocationPageNavTabs } from "locations/types";
import { Route, Routes } from "react-router-dom";
import ReportsHomePage from "reports/pages/home/ReportsHomePage";
import LocationInOutProductsReportPage from "reports/pages/product-location-movements/ProductLocationMovementsReportPage";
import TabsContainerPage from "shared/navigation-tab/components/tabs-container-page/TabsContainerPage";

const AppRoutes = () => (
  <Routes>
    <Route
      path="/"
      element={<MainLayout></MainLayout>}
      errorElement={<ErrorPage />}
    >
      {/* BRANDS BEGINS */}
      <Route
        path={COMPONENTS.BRANDS}
        element={<GrantAccess componentName={COMPONENTS.BRANDS}></GrantAccess>}
      >
        <Route path="" element={<BrandsListPage></BrandsListPage>}></Route>

        <Route path="new" element={<BrandPage></BrandPage>}></Route>
        <Route path="create" element={<BrandPage></BrandPage>}></Route>
        <Route path=":id" element={<BrandPage></BrandPage>}></Route>
      </Route>

      {/* BRANDS ENDS */}

      {/* LOCATIONS BEGINS */}
      <Route
        path={COMPONENTS.LOCATIONS}
        element={
          <GrantAccess componentName={COMPONENTS.LOCATIONS}></GrantAccess>
        }
      >
        <Route
          path=""
          element={<LocationsListPage></LocationsListPage>}
        ></Route>

        <Route path="create" element={<LocationPage></LocationPage>}></Route>
        <Route
          path=":id"
          element={
            <TabsContainerPage
              navTabs={LocationPageNavTabs}
            ></TabsContainerPage>
          }
        >
          <Route path="" element={<LocationPage></LocationPage>}></Route>
          <Route
            path="products"
            element={
              <GrantAccess componentName={COMPONENTS.LOCATIONS}></GrantAccess>
            }
          >
            <Route
              path=""
              element={
                <ProductsInLocationListPage></ProductsInLocationListPage>
              }
            ></Route>
          </Route>
        </Route>
      </Route>

      {/* LOCATIONS ENDS */}

      {/* PRODUCT LOCATION TXS BEGINS */}
      <Route
        path={COMPONENTS.PRODUCT_LOCATION_TXS}
        element={
          <GrantAccess
            componentName={COMPONENTS.PRODUCT_LOCATION_TXS}
          ></GrantAccess>
        }
      >
        <Route
          path=""
          element={<ProductLocationTxsListPage></ProductLocationTxsListPage>}
        ></Route>

        <Route
          path="new"
          element={<ProductLocationTxPage></ProductLocationTxPage>}
        ></Route>
        <Route
          path="create/:operationType"
          element={<ProductLocationTxPage></ProductLocationTxPage>}
        ></Route>
        <Route
          path=":id"
          element={<ProductLocationTxPage></ProductLocationTxPage>}
        ></Route>
      </Route>

      {/* PRODUCT LOCATION TXS ENDS */}

      {/* PRODUCTS BEGINS */}
      <Route
        path={COMPONENTS.PRODUCTS}
        element={
          <GrantAccess componentName={COMPONENTS.PRODUCTS}></GrantAccess>
        }
      >
        <Route path="" element={<ProductsListPage></ProductsListPage>}></Route>

        <Route path="new" element={<ProductPage></ProductPage>}></Route>
        <Route path="create" element={<ProductPage></ProductPage>}></Route>
        <Route
          path=":id"
          element={
            <TabsContainerPage navTabs={ProductPageNavTabs}></TabsContainerPage>
          }
        >
          <Route path="" element={<ProductPage></ProductPage>}></Route>
          <Route
            path="children"
            element={<ChildrenProductsListPage></ChildrenProductsListPage>}
          ></Route>
        </Route>
      </Route>

      {/* PRODUCTS ENDS */}

      {/* PRODUCT CATEGORIES BEGINS */}
      <Route
        path={COMPONENTS.PRODUCT_CATEGORIES}
        element={
          <GrantAccess
            componentName={COMPONENTS.PRODUCT_CATEGORIES}
          ></GrantAccess>
        }
      >
        <Route
          path=""
          element={<ProductCategoriesListPage></ProductCategoriesListPage>}
        ></Route>

        <Route
          path="new"
          element={<ProductCategoryPage></ProductCategoryPage>}
        ></Route>
        <Route
          path="create"
          element={<ProductCategoryPage></ProductCategoryPage>}
        ></Route>
        <Route
          path=":id"
          element={<ProductCategoryPage></ProductCategoryPage>}
        ></Route>
      </Route>

      {/* PRODUCT CATEGORIES ENDS */}

      {/* REPORTS BEGINS */}
      <Route
        path={COMPONENTS.REPORTS}
        element={<GrantAccess componentName={COMPONENTS.REPORTS}></GrantAccess>}
      >
        <Route path="" element={<ReportsHomePage></ReportsHomePage>}></Route>
        <Route
          path="product-location-movements"
          element={
            <LocationInOutProductsReportPage></LocationInOutProductsReportPage>
          }
        ></Route>
      </Route>

      {/* REPORTS ENDS */}

      {/* UNAUTHORIZED BEGINS */}

      <Route
        path="unauthorized"
        element={<UnauthorizedPage></UnauthorizedPage>}
      ></Route>

      {/* UNAUTHORIZED ENDS */}
    </Route>
    <Route path="auth/login" element={<LoginPage></LoginPage>}></Route>
    <Route path="*" element={<ErrorPage />} />
  </Routes>
);

export default AppRoutes;
