import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import styles from "./Drawer.module.scss";
import logo from "../../assets/images/app_icon.png";
import {
  BrandingWatermarkTwoTone,
  CategoryTwoTone,
  DeveloperBoardOutlined,
  LocationOnOutlined,
  ShoppingBagOutlined,
  SummarizeOutlined,
} from "@mui/icons-material";
import Breadcrumb from "../breadcrumbs/Breadcrumbs";
import DrawerMenuItem, { DrawerMenuItemProp } from "./DrawerMenuItem";
import AccountMenuButton from "../../auth/components/account-menu-button/AccountMenuButton";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store";
import PersistLogin from "../../auth/persist-login/PersistLogin";
import { useEffect } from "react";
import useAuthUser from "auth/hooks/useAuthUser";
import { COMPONENTS } from "configuration/types";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  // padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  margin: theme.spacing(2, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const items: DrawerMenuItemProp[] = [
    {
      text: "Entrada/Salida Productos",
      navigateTo: COMPONENTS.PRODUCT_LOCATION_TXS,
      children: <DeveloperBoardOutlined fontSize="large" />,
    },
    {
      text: "Productos",
      navigateTo: COMPONENTS.PRODUCTS,
      children: <ShoppingBagOutlined fontSize="large" />,
    },
    {
      text: "Marcas",
      navigateTo: "brands",
      children: <BrandingWatermarkTwoTone fontSize="large" />,
    },
    {
      text: "Categorías de Productos",
      navigateTo: "product-categories",
      children: <CategoryTwoTone fontSize="large" />,
    },
    {
      text: "Ubicaciones",
      navigateTo: COMPONENTS.LOCATIONS,
      children: <LocationOnOutlined fontSize="large" />,
    },
    {
      text: "Reportes",
      navigateTo: COMPONENTS.REPORTS,
      children: <SummarizeOutlined fontSize="large" />,
    },
  ];

  const user = useAuthUser();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <div className={styles.toolbar_content}>
            <div className={styles.toolbar_right}>
              <AccountMenuButton></AccountMenuButton>
            </div>
            <div className={styles.toolbar_left}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
              <h4 className={styles.user_name}>
                {"Facturas " + user?.currentLocation?.name ?? ""}
              </h4>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <img
            src={user?.currentOrganization?.imageUrl ?? logo}
            className={styles.logo}
          ></img>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {items.map((item) => (
            <DrawerMenuItem
              key={item.text}
              text={item.text}
              navigateTo={item.navigateTo}
              children={item.children}
              closeDrawer={() => {
                setOpen(false);
              }}
            ></DrawerMenuItem>
          ))}
        </List>
        <Divider />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {/* <Breadcrumb path={location.pathname}></Breadcrumb> */}
        <PersistLogin></PersistLogin>
      </Main>
    </Box>
  );
}
