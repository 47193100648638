import { IconButton } from "@mui/material";
import { useCallback, useState } from "react";
import LoadingPage from "shared/components/loading/LoadingPage";
import { Identity } from "shared/types/identity";
import styles from "./CustomDataGrid.module.scss";
import { Add, Delete, Edit } from "@mui/icons-material";
import DataGrid, {
  Pager,
  Paging,
  Export,
  ColumnChooser,
  FilterRow,
  GroupPanel,
  Column,
  HeaderFilter,
  StateStoring,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { jsPDF } from "jspdf";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";
import {
  CustomDataGridColumn,
  CustomSummaryGridColumn,
} from "shared/components/custom-data-grid/types";
import ConfirmPortal from "shared/portals/confirm/ConfirmPortal";

interface CustomDataGridProps {
  controlName: string;
  loading: boolean;
  data: Identity[];
  columns: CustomDataGridColumn[];
  summaryTotalColumns?: CustomSummaryGridColumn[];
  keyField?: string | string[];
  allowFilter?: boolean;
  allowGrouping?: boolean;
  allowExporting?: boolean;
  showColumnChooser?: boolean;
  allowReorderColumns?: boolean;
  selectionMode?: "single" | "multiple" | "none";
  pageSize?: number;
  onAdd?: () => void;
  onEdit?: (obj: Identity) => void;
  onDelete?: (id: string) => void;
  onSelectionChanged?: (items: Identity[] | null) => void;
}

const exportFormats = ["pdf", "xlsx"];

const CustomDataGrid = ({
  loading,
  data,
  columns,
  summaryTotalColumns,
  onAdd,
  onEdit,
  onDelete,
  onSelectionChanged,
  controlName,
  keyField = "id",
  selectionMode = "single",
  allowFilter = true,
  allowGrouping = true,
  allowExporting = false,
  showColumnChooser = false,
  allowReorderColumns = true,
  pageSize = 15,
}: CustomDataGridProps) => {
  const onExporting = useCallback((e: any) => {
    if (e.format === "xlsx") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");

      exportDataGridToExcel({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "DataGrid.xlsx"
          );
        });
      });
      e.cancel = true;
    } else if (e.format === "pdf") {
      const doc = new jsPDF();

      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: e.component,
      }).then(() => {
        doc.save("doc.pdf");
      });
    }
  }, []);

  const [selectedRows, setSelectedRows] = useState<Identity[]>([]);
  const [showConfirmPortal, setShowConfirmPortal] = useState<boolean>(false);

  if (loading) {
    return <LoadingPage></LoadingPage>;
  }

  return (
    <>
      {showConfirmPortal && (
        <ConfirmPortal
          title={"Está seguro que desea eliminar el item?"}
          handleAccept={() => {
            setShowConfirmPortal(false);
            onDelete && onDelete(selectedRows[0].id!);
          }}
          handleCancel={() => setShowConfirmPortal(false)}
        ></ConfirmPortal>
      )}

      <div className={styles.tool_bar}>
        {selectedRows.length > 0 && onDelete && (
          <IconButton
            style={{ backgroundColor: "lightblue", marginRight: 5 }}
            onClick={() => {
              setShowConfirmPortal(true);
            }}
          >
            <Delete></Delete>
          </IconButton>
        )}
        {selectedRows.length > 0 && onEdit && (
          <IconButton
            style={{ backgroundColor: "lightblue", marginRight: 5 }}
            onClick={() => {
              onEdit(selectedRows[0]);
            }}
          >
            <Edit></Edit>
          </IconButton>
        )}{" "}
        {onAdd && (
          <IconButton
            style={{ backgroundColor: "lightblue", marginRight: 5 }}
            onClick={onAdd}
          >
            <Add></Add>
          </IconButton>
        )}
      </div>
      <div
        style={{
          width: "100%",
          maxWidth: "100vw",
          // height: "80%",
          maxHeight: "100vh",
        }}
      >
        <DataGrid
          dataSource={data}
          keyExpr={keyField}
          allowColumnReordering={allowReorderColumns}
          onSelectionChanged={({ selectedRowsData }) => {
            setSelectedRows(selectedRowsData);
            onSelectionChanged && onSelectionChanged(selectedRowsData);
          }}
          columnAutoWidth={true}
          selection={{ mode: selectionMode }}
          onExporting={onExporting}
          showColumnLines={true}
          showRowLines={true}
          showBorders={true}
          rowAlternationEnabled={true}
        >
          {columns &&
            columns.map((column) => (
              <Column
                key={column.field}
                caption={column.caption}
                dataField={column.field}
                visible={column.visible ?? true}
                format={column.format}
                dataType={column.dataType}
                customizeText={column.cellCustomTextFn}
                alignment={column.alignment ?? "center"}
                allowFiltering={column.allowFiltering ?? true}
                cellRender={column.cellRender}
                allowSorting={column.sortable ?? true}
              />
            ))}
          <FilterRow visible={allowFilter} />
          <HeaderFilter visible={true} />
          <GroupPanel visible={allowGrouping} />
          <ColumnChooser enabled={showColumnChooser} />
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey={controlName}
          />
          <Export
            enabled={allowExporting}
            formats={exportFormats}
            allowExportSelectedData={selectionMode === "multiple"}
          />
          <Paging defaultPageSize={pageSize} pageSize={pageSize} />
          <Pager visible={true} />
          {summaryTotalColumns && (
            <Summary>
              {summaryTotalColumns.map((sColumn) => (
                <TotalItem
                  key={sColumn.column}
                  column={sColumn.column}
                  summaryType={sColumn.summaryType}
                  customizeText={sColumn.customTextFn}
                />
              ))}
            </Summary>
          )}
        </DataGrid>
      </div>
    </>
  );
};

export default CustomDataGrid;
