import { actions as pcActions } from "./index";
import { actions as notificationsActions } from "../../../notifications/store/index";
import useAxios from "../../../shared/hooks/UseAxios";
import { useAppDispatch } from "../../../store/hooks";
import { Brand } from "../types";
import {
  HORIZONTAL_POSITIONS,
  NOTIFICATION_STATUS,
  VERTICAL_POSITIONS,
} from "../../../notifications/types";
import { FormDataFromObject } from "../../../shared/helpers/helper";

const BASE_URL = "brands";

const useBrandActions = () => {
  const dispatch = useAppDispatch();
  const axios = useAxios();

  const fetchBrands = async () => {
    dispatch(pcActions.loadBrandsStart());
    try {
      const response = await axios.get<Brand[] | null>(BASE_URL);
      let data = null;
      if (response?.data) {
        data = response.data?.map((e) => ({
          ...e,
          title: e.name,
        }));
      }
      dispatch(pcActions.loadBrandsSuccess(data));
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(pcActions.loadBrandsFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const fetchBrandById = async (id: string) => {
    dispatch(pcActions.loadBrandStart());
    try {
      const response = await axios.get<Brand | null>(`${BASE_URL}/${id}`);
      dispatch(pcActions.loadBrandSuccess(response.data));
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(pcActions.loadBrandFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const saveBrand = async (obj: Brand, image: File | null) => {
    dispatch(pcActions.saveBrandStart());
    let response;
    try {
      const formData = FormDataFromObject(obj);
      if (image) {
        formData.append("image", image);
      }
      const axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      if (obj.id) {
        response = await axios.patch<string>(BASE_URL, formData, axiosConfig);
      } else {
        response = await axios.post<string>(BASE_URL, formData, axiosConfig);
        obj.id = response.data;
      }

      dispatch(pcActions.saveBrandSuccess(obj));
      dispatch(
        notificationsActions.showNotification({
          title: "Éxito",
          message: "Marca guardada con éxito",
          status: NOTIFICATION_STATUS.SUCCESS,
          vertical: VERTICAL_POSITIONS.BOTTOM,
          horizontal: HORIZONTAL_POSITIONS.CENTER,
        })
      );
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(pcActions.saveBrandFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const clearCurrentBrand = async () => {
    dispatch(pcActions.clearCurrentBrand());
  };

  return {
    fetchBrands,
    fetchBrandById,
    saveBrand,
    clearCurrentBrand,
  };
};

export default useBrandActions;
