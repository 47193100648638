import { createSlice } from "@reduxjs/toolkit";
import { Brand } from "../types";

interface StateObject {
  brands: Brand[] | null;
  loadingBrands: boolean;
  errorBrands: string | null;
  brand: Brand | null;
  loadingBrand: boolean;
  errorBrand: string | null;
}

const initialState: StateObject = {
  brands: null,
  loadingBrands: false,
  errorBrands: null,
  brand: null,
  loadingBrand: false,
  errorBrand: null,
};

const brandsSlice = createSlice({
  name: "brands",
  initialState,
  reducers: {
    loadBrandsStart(state) {
      state.loadingBrands = true;
      state.errorBrands = null;
    },
    loadBrandsSuccess(state, action) {
      state.loadingBrands = false;
      state.errorBrands = null;
      state.brands = action.payload;
    },
    loadBrandsFailure(state, action) {
      state.loadingBrands = false;
      state.errorBrands = action.payload;
    },
    loadBrandStart(state) {
      state.loadingBrand = true;
      state.errorBrand = null;
    },
    loadBrandSuccess(state, action) {
      state.loadingBrand = false;
      state.errorBrand = null;
      state.brand = action.payload;
    },
    loadBrandFailure(state, action) {
      state.loadingBrand = false;
      state.errorBrand = action.payload;
    },
    saveBrandStart(state) {
      state.loadingBrand = true;
    },
    saveBrandSuccess(state, action) {
      state.loadingBrand = false;
      state.brand = action.payload;
      state.errorBrand = null;
    },
    saveBrandFailure(state, action) {
      state.loadingBrand = false;
      state.brand = null;
      state.errorBrand = action.payload;
    },
    clearCurrentBrand(state) {
      state.brand = null;
      state.loadingBrand = false;
      state.errorBrand = null;
    },
  },
});

export const actions = brandsSlice.actions;
export default brandsSlice.reducer;
