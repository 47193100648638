import ReactDOM from "react-dom";
import styles from "./CroppingModal.module.scss";
import { useEffect, useRef } from "react";
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
import { Button } from "@mui/material";
import BackdropPortal from "../../portals/Backdrop";

export interface CroppingProps {
  imagePreview: string;
  onCropped: (file: File | null) => void;
  onCancel: () => void;
}

const CroppingModal = ({
  imagePreview,
  onCropped,
  onCancel,
}: CroppingProps) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const cropperRef = useRef<Cropper>();

  useEffect(() => {
    if (imageRef.current) {
      cropperRef.current = new Cropper(imageRef.current, {
        zoomable: false,
        viewMode: 1,
        responsive: false,
        background: false,
      });
    }

    return () => {
      // Cleanup: Destroy the Cropper instance
      if (cropperRef.current) {
        cropperRef.current.destroy();
        cropperRef.current = undefined;
      }
    };
  }, []);
  return (
    <div className={styles.main_container}>
      <div className={styles.cropper_container}>
        <img ref={imageRef} id="image-presenter" src={imagePreview}></img>
      </div>
      <div className={styles.buttons_bar}>
        <Button
          className={styles.buttons}
          color="primary"
          variant="contained"
          fullWidth
          onClick={(e) => {
            e.preventDefault();
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          className={styles.buttons}
          color="primary"
          variant="contained"
          fullWidth
          onClick={(e) => {
            e.preventDefault();
            cropperRef.current?.getCroppedCanvas().toBlob((blob) => {
              let file: File | null = null;
              if (blob) {
                file = new File([blob], "croppedImage", {
                  type: blob.type,
                });
              }
              onCropped(file || null);
            }, "image/jpeg");
          }}
        >
          Crop
        </Button>
      </div>
    </div>
  );
};

const CroppingPortal = ({
  imagePreview,
  onCropped,
  onCancel,
}: CroppingProps) => {
  return (
    <>
      <BackdropPortal>
        {ReactDOM.createPortal(
          <div
            style={{
              position: "fixed",
              width: "100%",
              zIndex: 99999,
            }}
          >
            <CroppingModal
              imagePreview={imagePreview}
              onCropped={onCropped}
              onCancel={onCancel}
            ></CroppingModal>
          </div>,
          document.getElementById("overlay-root") as HTMLElement
        )}
      </BackdropPortal>
    </>
  );
};

export default CroppingPortal;
