import {
  Action,
  AnyAction,
  Reducer,
  ThunkAction,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import authReducer from "../auth/store";
import brandsReducer from "../inventory/brand/store";
import locationsReducer from "locations/store";
import notificationsReducer from "../notifications/store";
import productCategoriesReducer from "../inventory/product-category/store";
import productLocationTxsReducer from "inventory/product-location-tx/store";
import productsReducer from "inventory/product/store";
import reportsReducer from "reports/store";
import { actions as authActions } from "../auth/store/index";

const combinedReducers = combineReducers({
  auth: authReducer,
  brands: brandsReducer,
  locations: locationsReducer,
  notifications: notificationsReducer,
  productCategories: productCategoriesReducer,
  productLocationTxs: productLocationTxsReducer,
  products: productsReducer,
  reports: reportsReducer,
});

//Reseting state but Auth when changing Current Organization
const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === authActions.updateUserCurrentOrganization.type) {
    state = {
      auth: state.auth,
    } as RootState;
  }
  return combinedReducers(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
