import { getPropertyValueFromPath } from "shared/helpers/helper";
import styles from "./ListViewItem.module.scss";
import noImage from "assets/images/no_image.png";
import { IHiearchyMember } from "shared/types/ihierarchy-member";
import { Tooltip } from "@mui/material";

export interface IListViewItem extends IHiearchyMember {
  name?: string;
  description?: string;
  imageUrl?: string | null;
  selected?: boolean;
  nameField?: string;
  imageField?: string;
}

export interface ListViewItemProp {
  item: IListViewItem;
  handleClick: (e: IListViewItem) => void;
  imagePath?: string;
  titlePath?: string;
  footer?: any;
}

const ListViewItem = ({
  item,
  imagePath,
  titlePath,
  handleClick,
  footer,
}: ListViewItemProp) => {
  return (
    <Tooltip title={item.description ?? ""}>
      <li onClick={() => handleClick(item)}>
        <div className={styles.item_contianer}>
          <img
            src={
              (imagePath
                ? getPropertyValueFromPath(item, imagePath)
                : item.imageUrl ?? undefined) ?? noImage
            }
            alt=""
          />
          {item.selected && (
            <div className={styles.overlay}>
              {/* <span>
                {titlePath
                  ? getPropertyValueFromPath(item, titlePath)
                  : item.name ?? undefined}
              </span> */}
            </div>
          )}

          <div className={styles.footer_container}>
            {typeof footer === "function" && footer()}
          </div>
        </div>
      </li>
    </Tooltip>
  );
};

export default ListViewItem;
