import { actions } from "locations/store/index";
import { actions as notificationsActions } from "notifications/store/index";
import useAxios from "shared/hooks/UseAxios";
import { useAppDispatch } from "store/hooks";
import {
  HORIZONTAL_POSITIONS,
  NOTIFICATION_STATUS,
  VERTICAL_POSITIONS,
} from "notifications/types";
import { Location, ProductLocation } from "locations/types";
import { Product } from "inventory/product/types";

const BASE_URL_LOCATIONS = "locations";
const BASE_URL_PRODUCT_LOCATIONS = "product-locations";

const useActions = () => {
  const dispatch = useAppDispatch();
  const axios = useAxios();

  const fetchLocations = async () => {
    dispatch(actions.loadLocationsStart());
    try {
      const response = await axios.get<Location[] | null>(BASE_URL_LOCATIONS);

      dispatch(actions.loadLocationsSuccess(response.data));
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(actions.loadLocationsFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const fetchLocationById = async (id: string) => {
    dispatch(actions.loadLocationStart());
    try {
      const response = await axios.get<Location[] | null>(
        `${BASE_URL_LOCATIONS}/${id}`
      );
      dispatch(actions.loadLocationSuccess(response.data));
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(actions.loadLocationFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const saveLocation = async (obj: Location) => {
    dispatch(actions.saveLocationStart());
    let response;

    console.log("obj", obj);
    try {
      if (obj.id) {
        response = await axios.patch(BASE_URL_LOCATIONS, obj);
      } else {
        response = await axios.post(BASE_URL_LOCATIONS, obj);
      }

      dispatch(actions.saveLocationSuccess(obj));
      dispatch(
        notificationsActions.showNotification({
          title: "Éxito",
          message: "Ubicación guardada con éxito",
          status: NOTIFICATION_STATUS.SUCCESS,
          vertical: VERTICAL_POSITIONS.BOTTOM,
          horizontal: HORIZONTAL_POSITIONS.CENTER,
        })
      );
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(actions.saveLocationFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const clearCurrentLocation = async () => {
    dispatch(actions.clearCurrentLocation());
  };

  const fetchProductsInLocation = async (location: Location) => {
    dispatch(actions.loadProductsInLocationStart());
    try {
      const response = await axios.get<Product[]>(
        `${BASE_URL_LOCATIONS}/${location.id}/products`
      );

      const updatedLocation = { ...location, products: response.data };

      dispatch(actions.loadProductsInLocationSuccess(updatedLocation));
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(actions.loadLocationsFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const fetchProductsInLocationV2 = async (location: Location) => {
    dispatch(actions.loadProductsInLocationStart());
    try {
      const response = await axios.get<ProductLocation[]>(
        `${BASE_URL_LOCATIONS}/${location.id}/products-in-location`
      );

      const updatedLocation: Location = {
        ...location,
        productsInLocation: response.data,
      };

      dispatch(actions.loadProductsInLocationSuccess(updatedLocation));
      dispatch(actions.loadLocationSuccess(updatedLocation));
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(actions.loadLocationsFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const fetchProductsNotInLocation = async (
    location: Location,
    productParentId: string | null = null
  ) => {
    dispatch(actions.loadProductsInLocationStart());
    try {
      const params = {
        productParentId,
      };

      const response = await axios.get<Product[]>(
        `${BASE_URL_PRODUCT_LOCATIONS}/${location.id}/products-not-in-location`,
        { params }
      );

      const updatedLocation: Location = {
        ...location,
        productsNotInLocation: response.data,
      };

      dispatch(actions.loadProductsInLocationSuccess(updatedLocation));
      dispatch(actions.loadLocationSuccess(updatedLocation));

      return response.data;
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(actions.loadLocationsFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const updateProductLocation = async (obj: ProductLocation) => {
    dispatch(actions.updateProductLocationStart());
    let response;

    try {
      if (obj.id) {
        response = await axios.patch<ProductLocation>(
          `${BASE_URL_PRODUCT_LOCATIONS}`,
          obj
        );
      }

      dispatch(actions.updateProductLocationSuccess(obj));
      dispatch(
        notificationsActions.showNotification({
          title: "Éxito",
          message: "Producto en Ubicación guardado con éxito",
          status: NOTIFICATION_STATUS.SUCCESS,
          vertical: VERTICAL_POSITIONS.BOTTOM,
          horizontal: HORIZONTAL_POSITIONS.CENTER,
        })
      );
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(actions.updateProductLocationFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const addProductsToLocation = async (
    locationId: string,
    productIds: string[]
  ) => {
    dispatch(actions.addProductsToLocationStart());

    const payload = {
      locationId,
      productIds,
    };

    try {
      const response = await axios.post<ProductLocation[]>(
        `${BASE_URL_PRODUCT_LOCATIONS}`,
        payload
      );

      dispatch(actions.addProductsToLocationSuccess(response.data));
      dispatch(
        notificationsActions.showNotification({
          title: "Éxito",
          message: "Producto(s) agregado(s) a Ubicación con éxito",
          status: NOTIFICATION_STATUS.SUCCESS,
          vertical: VERTICAL_POSITIONS.BOTTOM,
          horizontal: HORIZONTAL_POSITIONS.CENTER,
        })
      );
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(actions.addProductsToLocationFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const updateLocationFromStore = async (locationId: string) => {
    dispatch(actions.updateLocationFromStore(locationId));
  };

  return {
    fetchLocations,
    fetchLocationById,
    saveLocation,
    addProductsToLocation,
    clearCurrentLocation,
    fetchProductsInLocation,
    fetchProductsInLocationV2,
    fetchProductsNotInLocation,
    updateLocationFromStore,
    updateProductLocation,
  };
};

export { useActions };
export default useActions;
