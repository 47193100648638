import { getPropertyValueFromPath } from "shared/helpers/helper";
import styles from "./AccessItem.module.scss";
import noImage from "assets/images/no_image.png";
import { IHiearchyMember } from "shared/types/ihierarchy-member";
import { Tooltip } from "@mui/material";

export interface IAccessItem extends IHiearchyMember {
  name?: string;
  description?: string;
  imageUrl?: string | null;
  selected?: boolean;
  nameField?: string;
  imageField?: string;
}

export interface AccessItemProp {
  item: IAccessItem;
  handleClick: (e: IAccessItem) => void;
  imagePath?: string;
  titlePath?: string;
  footer?: any;
}

const AccessItem = ({
  item,
  imagePath,
  titlePath,
  handleClick,
  footer,
}: AccessItemProp) => {
  return (
    <Tooltip title={item.description ?? ""}>
      <li onClick={() => handleClick(item)}>
        <div className={styles.image_item}>
          <img
            src={
              (imagePath
                ? getPropertyValueFromPath(item, imagePath)
                : item.imageUrl ?? undefined) ?? noImage
            }
            alt=""
          />
          {item.selected && (
            <div className={styles.overlay}>
              <span>
                {titlePath
                  ? getPropertyValueFromPath(item, titlePath)
                  : item.name ?? undefined}
              </span>
            </div>
          )}
        </div>
        <div className={styles.footer_container}>
          {typeof footer === "function" && footer()}
        </div>
      </li>
    </Tooltip>
  );
};

export default AccessItem;
