import * as yup from "yup";

export const ProductLocationTxSchema = yup.object({
  currentLocationId: yup.string().required("Ubicación actual es requerida"),
  involvedLocationId: yup.string().required("Ubicación externa es requerida"),
  txDate: yup.date().required("Fecha es requerida"),
  operationTypeId: yup.number().required("Tipo de operación es requerida"),
  referenceCode: yup.string().required("Número de factura es requerido"),
  statusId: yup.number().required("Estado es requerido"),
  txDetails: yup.array().test({
    message: "Al menos 1 detalle es requerido",
    test: (arr) => arr != null && arr?.length > 0,
  }),
});

export const ProductLocationTxDetailSchema = yup.object({
  productId: yup.string().required("Producto es requerido"),
  quantity: yup.number().required("Cantidad es requerida"),
});
