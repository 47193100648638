import PositionedSnackbar from "notifications/snackbar/Snackbar";
import styles from "shared/styles/Page.module.scss";
import { useAppSelector } from "store/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { RootState } from "store";
import useActions from "locations/store/useActions";
import useAuthUser from "auth/hooks/useAuthUser";
import { Location } from "locations/types";
import LocationForm from "locations/components/form/LocationForm";
import { CatalogItem } from "shared/types/catalog";

const LocationPage = () => {
  const params = useParams();

  const formData = useAppSelector<Location | null>(
    (state: RootState) => state.locations.location
  );
  const loading = useAppSelector(
    (state: RootState) => state.locations.loadingLocation
  );
  const notification = useAppSelector(
    (state) => state.notifications.notification
  );

  const locationTypes: CatalogItem[] = [
    {
      catalogId: "LocationTypes",
      id: 1,
      name: "TIENDA",
      isActive: true,
    },
    {
      catalogId: "LocationTypes",
      id: 2,
      name: "BODEGA",
      isActive: true,
    },
  ];

  const { fetchLocationById, saveLocation } = useActions();

  const user = useAuthUser();

  useEffect(() => {
    const id = params.id;

    if (id) {
      fetchLocationById(id);
    }
  }, []);

  const navigate = useNavigate();

  const onSave = (obj: Location) => {
    saveLocation(obj);
  };

  return (
    <>
      <div className={styles.page_content}>
        <LocationForm
          formData={formData}
          handleSubmit={onSave}
          loading={loading}
          locationTypes={locationTypes}
          handleCancel={() => {
            navigate("/locations");
          }}
        ></LocationForm>
        {notification && (
          <PositionedSnackbar
            {...{ ...notification, open: !!notification }}
          ></PositionedSnackbar>
        )}
      </div>
    </>
  );
};

export default LocationPage;
