export enum PERMISSIONS {
  CREATE = "create",
  READ = "read",
  UPDATE = "update",
  DELETE = "delete",
  EXPORT = "export",
  PRINT = "print",
}

export enum COMPONENTS {
  BRANDS = "brands",
  LOCATIONS = "locations",
  PRODUCT_CATEGORIES = "product-categories",
  PRODUCT_LOCATION_TXS = "product-location-txs",
  PRODUCTS = "products",
  REPORTS = "reports",
}
