import ReactDOM from "react-dom";
import Backdrop from "shared/components/portals/Backdrop";
import portalStyles from "shared/styles/Portal.module.scss";
import sharedStyles from "shared/styles/Form.module.scss";
import { Button } from "@mui/material";

interface PortalProps {
  title: string;
  handleAccept: () => void;
  handleCancel: () => void;
}
const ConfirmPortal = ({ title, handleAccept, handleCancel }: PortalProps) => (
  <>
    <Backdrop></Backdrop>,
    {ReactDOM.createPortal(
      <div className={portalStyles.portal_content}>
        <h3>{title}</h3>
        <div className={sharedStyles.buttons_bar}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleCancel}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleAccept}
          >
            Aceptar
          </Button>
        </div>
      </div>,
      document.getElementById("overlay-root") as HTMLElement
    )}
  </>
);

export default ConfirmPortal;
