import {
  HORIZONTAL_POSITIONS,
  NOTIFICATION_STATUS,
  Notification,
  VERTICAL_POSITIONS,
} from "../types";
import { createSlice } from "@reduxjs/toolkit";

interface stateObject {
  notification: Notification | null;
}

const initialState: stateObject = {
  notification: null,
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    showNotification(state, action) {
      state.notification = action.payload;
    },
    hideNotification(state) {
      state.notification = null;
    },
    showErrorNotification(state, action) {
      state.notification = {
        title: "Error",
        message: action.payload,
        status: NOTIFICATION_STATUS.ERROR,
        vertical: VERTICAL_POSITIONS.BOTTOM,
        horizontal: HORIZONTAL_POSITIONS.CENTER,
      };
    },
  },
});

export const actions = notificationsSlice.actions;
export default notificationsSlice.reducer;
