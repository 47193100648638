import MainList from "../../../../shared/components/main-list/MainList";
import { useAppSelector } from "../../../../store/hooks";
import { useEffect } from "react";
import { RootState } from "../../../../store";
import { IAccessItem } from "shared/components/access-item/AccessItem";
import { useNavigate } from "react-router-dom";
import useProductCategoryActions from "../../store/useActions";
import { ItemWithNameFooter } from "shared/components/access-item/access-item-templates/Footers";
import { COMPONENTS } from "configuration/types";

const ProductCategoriesListPage = () => {
  const navigate = useNavigate();
  const productCategories = useAppSelector(
    (state: RootState) => state.productCategories.productCategories
  );
  const loading = useAppSelector(
    (state: RootState) => state.productCategories.loadingProductCategories
  );

  const { fetchProductCategories, clearCurrentProductCategory } =
    useProductCategoryActions();
  useEffect(() => {
    fetchProductCategories();
  }, []);

  const handleClick = (e: IAccessItem) => {
    navigate(e.id!);
  };

  const handleNew = () => {
    clearCurrentProductCategory().then(() => {
      navigate("create");
    });
  };

  const onSearch = (term: string, data: any) => {
    const searchTerm = term.toUpperCase();
    const result = data?.filter((item: any) => {
      if (item.name?.includes(searchTerm)) {
        return item;
      }
    });

    return result;
  };

  return (
    <>
      <MainList
        data={productCategories}
        onItemClick={handleClick}
        onAddClick={handleNew}
        loading={loading}
        onSearch={onSearch}
        gridViewFooter={ItemWithNameFooter}
        controlOwnerName={COMPONENTS.PRODUCT_CATEGORIES}
      ></MainList>
    </>
  );
};

export default ProductCategoriesListPage;
