import { CircularProgress } from "@mui/material";
import styles from "./Loading.module.scss";

interface PageProps {
  message?: string;
}

const LoadingPage = ({ message = "CARGANDO..." }: PageProps) => {
  return (
    <div className={styles.main_container}>
      <div className={styles.content}>
        <CircularProgress></CircularProgress>
        <h1>{message}</h1>
      </div>
    </div>
  );
};

export default LoadingPage;
