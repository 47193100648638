import { createSlice } from "@reduxjs/toolkit";
import { Product } from "inventory/product/types";
import { IHiearchyMember } from "shared/types/ihierarchy-member";

interface StateObject {
  products: Product[] | null;
  loadingProducts: boolean;
  errorProducts: string | null;
  product: Product | null;
  loadingProduct: boolean;
  errorProduct: string | null;
}

const initialState: StateObject = {
  products: null,
  loadingProducts: false,
  errorProducts: null,
  product: null,
  loadingProduct: false,
  errorProduct: null,
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    loadProductsStart(state) {
      state.loadingProducts = true;
      state.errorProducts = null;
    },
    loadProductsSuccess(state, action) {
      state.loadingProducts = false;
      state.errorProducts = null;
      state.products = action.payload;
    },
    loadProductsFailure(state, action) {
      state.loadingProducts = false;
      state.errorProducts = action.payload;
    },
    loadProductStart(state) {
      state.loadingProduct = true;
      state.errorProduct = null;
    },
    loadProductSuccess(state, action) {
      state.loadingProduct = false;
      state.errorProduct = null;
      state.product = action.payload;
    },
    loadProductFailure(state, action) {
      state.loadingProduct = false;
      state.errorProduct = action.payload;
    },
    saveProductStart(state) {
      state.loadingProduct = true;
    },
    saveProductSuccess(state, action) {
      state.loadingProduct = false;
      state.product = action.payload;
      state.errorProduct = null;
    },
    saveProductFailure(state, action) {
      state.loadingProduct = false;
      state.product = null;
      state.errorProduct = action.payload;
    },
    clearCurrentProduct(state) {
      state.product = null;
      state.loadingProduct = false;
      state.errorProduct = null;
    },
    createProductFromParentSuccess(state, action) {
      state.loadingProduct = false;
      state.errorProduct = null;
    },
    loadChildrenStart(state) {
      state.loadingProducts = true;
      state.errorProducts = null;
    },
    loadChildrenSuccess(state, action) {
      state.loadingProducts = false;
      state.errorProducts = null;

      const children = action.payload.children as Product[];
      const parent = action.payload.parent as IHiearchyMember;

      state.products = state.products
        ? state.products.map((product) =>
            product.id === parent.id
              ? { ...product, children: children }
              : product
          )
        : null;
    },
    loadChildrenFailure(state, action) {
      state.loadingProducts = false;
      state.errorProducts = action.payload;
    },
  },
});

export const actions = productsSlice.actions;
export default productsSlice.reducer;
