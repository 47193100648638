import { useFormik } from "formik";
import { ProductCategory } from "../../types";
import { productCategorySchema } from "../../validations";
import sharedStyles from "shared/styles/Form.module.scss";
import { Button, FormControlLabel, Switch, TextField } from "@mui/material";
import ImagePresenter from "../../../../shared/components/image-presenter/ImagePresenter";
import { useState } from "react";
import LoadingPage from "../../../../shared/components/loading/LoadingPage";

interface FormProps {
  handleSubmit: (entityObj: ProductCategory, image: File | null) => void;
  handleCancel: () => void;
  formData: ProductCategory | null;
  loading: boolean;
}

const ProductCategoryForm = ({
  handleSubmit,
  handleCancel,
  formData,
  loading,
}: FormProps) => {
  const formik = useFormik<ProductCategory>({
    initialValues: formData ?? {
      id: null,
      name: "",
      imageUrl: null,
      isActive: true,
    },
    enableReinitialize: true,
    validationSchema: productCategorySchema,
    onSubmit: async (values) => {
      handleSubmit(values, image);
    },
  });

  const [image, setImage] = useState<File | null>(null);

  if (loading) {
    return <LoadingPage></LoadingPage>;
  }

  return (
    <div className={sharedStyles.form_content}>
      <form onSubmit={formik.handleSubmit}>
        <ImagePresenter
          imageUrl={formData?.imageUrl ?? null}
          onChange={(e: File | null) => {
            setImage(e);
          }}
        ></ImagePresenter>
        <div className={sharedStyles.form_section_row}>
          <div className={sharedStyles.form_row}>
            <TextField
              className={sharedStyles.input_field}
              type="string"
              id="name"
              name="name"
              label="Nombre Categoría"
              autoComplete="no"
              value={formik.values.name || ""}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              InputLabelProps={{ shrink: formik.values.name != null }}
            ></TextField>
          </div>
        </div>
        <div className={sharedStyles.form_section_row}>
          <div className={sharedStyles.form_row}>
            <FormControlLabel
              control={
                <Switch
                  id="isActive"
                  name="isActive"
                  checked={formik.values.isActive}
                  onChange={formik.handleChange}
                />
              }
              label="Activo"
              labelPlacement="start"
            />
          </div>
        </div>
        <div className={sharedStyles.buttons_bar}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleCancel}
          >
            Cancelar
          </Button>
          <Button color="primary" variant="contained" fullWidth type="submit">
            Guardar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ProductCategoryForm;
