import { Product } from "inventory/product/types";
import { LinkTabProps } from "shared/navigation-tab/components/link-tab/LinkTab";
import { Identity } from "shared/types/identity";

export interface Location extends Identity {
  name: string;
  typeId: number;
  typeName?: string;
  isExternal: boolean;
  isActive: boolean;
  products?: Product[] | null;
  productsInLocation?: ProductLocation[] | null;
  productsNotInLocation?: Product[] | null;
}

export interface ProductLocation extends Identity {
  locationId: string;
  productId: string;
  product?: Product | null;
  quantity: number;
  isActive: boolean;
}

export const LocationPageNavTabs: LinkTabProps[] = [
  {
    label: "General",
    href: "",
  },
  {
    label: "Productos",
    href: "products",
  },
];
