import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export interface DrawerMenuItemProp {
  children?: React.ReactNode;
  text: string;
  navigateTo?: string;
  onClick?: () => void;
  closeDrawer?: () => void;
}

const DrawerMenuItem = ({
  children,
  text,
  navigateTo,
  onClick,
  closeDrawer,
}: DrawerMenuItemProp) => {
  const navigate = useNavigate();
  const handleClick = (e: any) => {
    closeDrawer && closeDrawer();
    if (navigateTo) {
      e.preventDefault();
      navigate(navigateTo);
    } else {
      onClick && onClick();
    }
  };
  return (
    <ListItem key={text} disablePadding>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>{children}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
};

export default DrawerMenuItem;
