import { createSlice } from "@reduxjs/toolkit";
import { AuthUser } from "../types";

interface StateObject {
  user: AuthUser | null;
  loadingUser: boolean;
  errorUser: string | null;
}

const initialState: StateObject = {
  user: null,
  loadingUser: false,
  errorUser: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginStart(state) {
      state.user = null;
      state.loadingUser = true;
      state.errorUser = null;
    },
    loginSuccess(state, action) {
      state.loadingUser = false;
      state.errorUser = null;
      state.user = action.payload;
    },
    loginFailure(state, action) {
      state.user = null;
      state.loadingUser = false;
      state.errorUser = action.payload;
    },
    loadUserFromSession(state, action) {
      state.user = action.payload;
      state.loadingUser = false;
      state.errorUser = null;
    },
    loadUserFromSessionFailure(state, action) {
      state.user = null;
      state.loadingUser = false;
      state.errorUser = action.payload;
    },
    refreshingTokenStart(state) {
      state.loadingUser = true;
      state.errorUser = null;
    },
    refreshingTokenSuccess(state, action) {
      state.loadingUser = false;
      state.user = action.payload;
    },
    refreshingTokenFailure(state, action) {
      state.loadingUser = false;
      state.errorUser = action.payload;
    },
    updateUserCurrentOrganization(state, action) {
      if (state.user) {
        state.user.currentOrganization = action.payload;
      }
    },
  },
});

export const actions = authSlice.actions;
export default authSlice.reducer;
