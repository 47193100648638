import { Button, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  HORIZONTAL_POSITIONS,
  NOTIFICATION_STATUS,
  VERTICAL_POSITIONS,
} from "../types";
import { useAppDispatch } from "../../store/hooks";
import { actions as notificationsActions } from "../store";

export interface NotificationProps {
  title: string;
  message: string;
  status: NOTIFICATION_STATUS;
  vertical: VERTICAL_POSITIONS;
  horizontal: HORIZONTAL_POSITIONS;
  open: boolean;
}

const PositionedSnackbar = ({
  title,
  message,
  status,
  vertical,
  horizontal,
  open,
}: NotificationProps) => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(notificationsActions.hideNotification());
  };

  const action = (
    <>
      <Button color="secondary" size="small" onClick={handleClose}>
        Cerrar
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <div>
      <Snackbar
        open={open}
        title={title}
        message={message}
        onClose={handleClose}
        anchorOrigin={{
          vertical: vertical,
          horizontal: horizontal,
        }}
        action={action}
      ></Snackbar>
    </div>
  );
};

export default PositionedSnackbar;
