import { actions } from "inventory/product-location-tx/store/index";
import { actions as notificationsActions } from "notifications/store/index";
import useAxios from "shared/hooks/UseAxios";
import { useAppDispatch } from "store/hooks";
import {
  HORIZONTAL_POSITIONS,
  NOTIFICATION_STATUS,
  VERTICAL_POSITIONS,
} from "notifications/types";
import { ProductLocationTx } from "inventory/product-location-tx/types";

const BASE_URL = "product-location-txs";

const useActions = () => {
  const dispatch = useAppDispatch();
  const axios = useAxios();

  const fetchProductLocationTxs = async (locationId: string) => {
    dispatch(actions.loadProductLocationTxsStart());
    try {
      const response = await axios.get<ProductLocationTx[] | null>(
        `${BASE_URL}/by-location/${locationId}`
      );

      dispatch(actions.loadProductLocationTxsSuccess(response.data));
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(actions.loadProductLocationTxsFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const fetchProductLocationTxById = async (id: string) => {
    dispatch(actions.loadProductLocationTxStart());
    try {
      const response = await axios.get<ProductLocationTx[] | null>(
        `${BASE_URL}/${id}`
      );
      dispatch(actions.loadProductLocationTxSuccess(response.data));
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(actions.loadProductLocationTxFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const saveProductLocationTx = async (obj: ProductLocationTx) => {
    dispatch(actions.saveProductLocationTxStart());
    let response;

    try {
      if (obj.id) {
        response = await axios.patch<ProductLocationTx>(BASE_URL, obj);
      } else {
        response = await axios.post<ProductLocationTx>(BASE_URL, obj);
      }

      dispatch(actions.saveProductLocationTxSuccess(response.data));
      dispatch(
        notificationsActions.showNotification({
          title: "Éxito",
          message: "TX guardada con éxito",
          status: NOTIFICATION_STATUS.SUCCESS,
          vertical: VERTICAL_POSITIONS.BOTTOM,
          horizontal: HORIZONTAL_POSITIONS.CENTER,
        })
      );
    } catch (error: any) {
      console.log(error);
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(actions.saveProductLocationTxFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const deleteProductLocationTxById = async (id: string) => {
    dispatch(actions.loadProductLocationTxStart());
    try {
      const response = await axios.delete(`${BASE_URL}/${id}`);
      dispatch(actions.loadProductLocationTxSuccess(null));
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(actions.loadProductLocationTxFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const clearCurrentProductLocationTx = async () => {
    dispatch(actions.clearCurrentProductLocationTx());
  };

  const getNextReferenceCodeByLocationId = async (
    currentLocationId: string,
    involvedLocationId: string
  ) => {
    dispatch(actions.getNextReferenceCodeStart());
    try {
      const queryParams = new URLSearchParams({
        currentLocationId,
        involvedLocationId,
      });
      const response = await axios.get<string>(
        `${BASE_URL}/next-reference-code?` + queryParams
      );
      dispatch(actions.getNextReferenceCodeSuccess(response.data));
      return response.data;
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(actions.getNextReferenceCodeFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  return {
    fetchProductLocationTxs,
    fetchProductLocationTxById,
    saveProductLocationTx,
    deleteProductLocationTxById,
    clearCurrentProductLocationTx,
    getNextReferenceCodeByLocationId,
  };
};

export default useActions;
