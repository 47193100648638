import { useEffect } from "react";
import useRefreshToken from "../../auth/hooks/UseRefreshToken";
import { useAppSelector } from "../../store/hooks";
import { axiosPrivate } from "../services/axios";

const useAxios = () => {
  const refreshToken = useRefreshToken();
  const user = useAppSelector((state) => state.auth.user);

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${user?.token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (
          [401, 403].includes(error?.response?.status) &&
          !prevRequest?.sent
        ) {
          prevRequest.sent = true;
          const refreshAuth = await refreshToken();
          prevRequest.headers["Authorization"] = `Bearer ${refreshAuth?.token}`;
          if (prevRequest.data instanceof FormData) {
            prevRequest.headers["Content-Type"] = "multipart/form-data";
          }

          // Process happens fast so by the time this code it executed credentials are not available in the Store
          // so credentials from refresh token methods are used directly
          if (!prevRequest.params.organizationId) {
            prevRequest.params.organizationId =
              refreshAuth?.userData.currentOrganization?.id;
          }

          return axiosPrivate(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    axiosPrivate.defaults.params = {
      organizationId: user?.currentOrganization?.id || null,
    };

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [user?.token, user?.currentOrganization, refreshToken]);

  return axiosPrivate;
};

export default useAxios;
