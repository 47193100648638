import { actions as pcActions } from "./index";
import { actions as notificationsActions } from "../../../notifications/store/index";
import useAxios from "../../../shared/hooks/UseAxios";
import { useAppDispatch } from "../../../store/hooks";
import { ProductCategory } from "../types";
import {
  HORIZONTAL_POSITIONS,
  NOTIFICATION_STATUS,
  VERTICAL_POSITIONS,
} from "../../../notifications/types";
import { FormDataFromObject } from "../../../shared/helpers/helper";

const BASE_URL = "product-categories";

const useProductCategoryActions = () => {
  const dispatch = useAppDispatch();
  const axios = useAxios();

  const fetchProductCategories = async () => {
    dispatch(pcActions.loadProductCategoriesStart());
    try {
      const response = await axios.get<ProductCategory[] | null>(BASE_URL);
      let data = null;
      if (response?.data) {
        data = response.data?.map((e) => ({
          ...e,
          title: e.name,
        }));
      }
      dispatch(pcActions.loadProductCategoriesSuccess(data));
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(pcActions.loadProductCategoriesFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const fetchProductCategoryById = async (id: string) => {
    dispatch(pcActions.loadProductCategoryStart());
    try {
      const response = await axios.get<ProductCategory[] | null>(
        `${BASE_URL}/${id}`
      );
      dispatch(pcActions.loadProductCategorySuccess(response.data));
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(pcActions.loadProductCategoryFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const saveProductCategory = async (
    obj: ProductCategory,
    image: File | null
  ) => {
    dispatch(pcActions.saveProductCategoryStart());
    let response;
    try {
      const formData = FormDataFromObject(obj);
      if (image) {
        formData.append("image", image);
      }
      const axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      if (obj.id) {
        response = await axios.patch<string>(BASE_URL, formData, axiosConfig);
      } else {
        response = await axios.post<string>(BASE_URL, formData, axiosConfig);
        obj.id = response.data;
      }

      dispatch(pcActions.saveProductCategorySuccess(obj));
      dispatch(
        notificationsActions.showNotification({
          title: "Éxito",
          message: "Categoria guardada con éxito",
          status: NOTIFICATION_STATUS.SUCCESS,
          vertical: VERTICAL_POSITIONS.BOTTOM,
          horizontal: HORIZONTAL_POSITIONS.CENTER,
        })
      );
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(pcActions.saveProductCategoryFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const clearCurrentProductCategory = async () => {
    dispatch(pcActions.clearCurrentProductCategory());
  };

  return {
    fetchProductCategories,
    fetchProductCategoryById,
    saveProductCategory,
    clearCurrentProductCategory,
  };
};

export default useProductCategoryActions;
