import { actions } from "../store/index";
import { actions as notificationsActions } from "../../notifications/store/index";
import useAxios from "../../shared/hooks/UseAxios";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { AuthUser } from "../types";
import { Organization } from "../../organization/types";
import { useLocation, useNavigate } from "react-router-dom";
import { Login } from "../login/types";
import { RootState } from "../../store";

const BASE_URL = "auth";

const useAuthActions = () => {
  const dispatch = useAppDispatch();
  const axios = useAxios();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/product-location-txs";

  const updateUserCurrentOrganization = async (
    newOrganization: Organization
  ) => {
    dispatch(actions.updateUserCurrentOrganization(newOrganization));
    navigate("/");
  };

  const login = async (login: Login) => {
    dispatch(actions.loginStart());
    try {
      const response = await axios.post(`${BASE_URL}/sign-in`, login, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      const { defaultOrganization, defaultLocation, ...rest } = response.data;

      const user: AuthUser = {
        ...rest,
        currentOrganization: defaultOrganization,
        currentLocation: defaultLocation,
      };

      dispatch(actions.loginSuccess(user));
      navigate(from, { replace: true });
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;

      dispatch(actions.loginFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const logout = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/sign-out`, {
        withCredentials: true,
      });
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(notificationsActions.showErrorNotification(errorMsg));
      console.error(error);
    } finally {
      navigate(`${BASE_URL}/login`, { replace: true });
    }
  };

  return {
    login,
    logout,
    updateUserCurrentOrganization,
  };
};

export default useAuthActions;
