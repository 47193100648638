import { actions } from "inventory/product/store/index";
import { actions as notificationsActions } from "notifications/store/index";
import useAxios from "shared/hooks/UseAxios";
import { useAppDispatch } from "store/hooks";
import {
  HORIZONTAL_POSITIONS,
  NOTIFICATION_STATUS,
  VERTICAL_POSITIONS,
} from "notifications/types";
import {
  ChildProductPropertiesToUpdate,
  Product,
} from "inventory/product/types";
import { FormDataFromObject } from "shared/helpers/helper";

import { IAccessItem } from "shared/components/access-item/AccessItem";

const BASE_URL = "products";

const useActions = () => {
  const dispatch = useAppDispatch();
  const axios = useAxios();

  const fetchProducts = async (parentId?: string) => {
    dispatch(actions.loadProductsStart());
    try {
      const response = await axios.get<Product[] | null>(`${BASE_URL}`, {
        params: {
          parentId: parentId,
        },
      });

      dispatch(actions.loadProductsSuccess(response.data));
      return response.data;
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(actions.loadProductsFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
      return [];
    }
  };

  const fetchProductById = async (id: string) => {
    dispatch(actions.loadProductStart());
    try {
      const response = await axios.get<Product | null>(`${BASE_URL}/${id}`);
      dispatch(actions.loadProductSuccess(response.data));
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(actions.loadProductFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const saveProduct = async (obj: Product, image: File | null) => {
    dispatch(actions.saveProductStart());
    let response;

    try {
      const formData = FormDataFromObject(obj);
      if (image) {
        formData.append("image", image);
      }
      const axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      if (obj.id) {
        response = await axios.patch<Product>(BASE_URL, formData, axiosConfig);
      } else {
        response = await axios.post<Product>(BASE_URL, formData, axiosConfig);
      }

      dispatch(actions.saveProductSuccess(response.data));
      dispatch(
        notificationsActions.showNotification({
          title: "Éxito",
          message: "Producto guardado con éxito",
          status: NOTIFICATION_STATUS.SUCCESS,
          vertical: VERTICAL_POSITIONS.BOTTOM,
          horizontal: HORIZONTAL_POSITIONS.CENTER,
        })
      );
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(actions.saveProductFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const updateProductAndChildren = async (
    obj: Product,
    image: File | null,
    childrenProps: ChildProductPropertiesToUpdate
  ) => {
    dispatch(actions.saveProductStart());

    try {
      const formData = FormDataFromObject(obj);

      const doNotUpdateChildren = Object.values(childrenProps).every(
        (value) => value === false
      );

      if (!doNotUpdateChildren) {
        formData.append("propsToUpdate", JSON.stringify(childrenProps));
      }

      if (image) {
        formData.append("image", image);
      }
      const axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.patch<Product>(
        BASE_URL,
        formData,
        axiosConfig
      );

      dispatch(actions.saveProductSuccess(response.data));
      dispatch(
        notificationsActions.showNotification({
          title: "Éxito",
          message: "Producto e hijos guardado con éxito",
          status: NOTIFICATION_STATUS.SUCCESS,
          vertical: VERTICAL_POSITIONS.BOTTOM,
          horizontal: HORIZONTAL_POSITIONS.CENTER,
        })
      );
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(actions.saveProductFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const deleteProductById = async (id: string) => {
    dispatch(actions.loadProductStart());
    try {
      const response = await axios.delete(`${BASE_URL}/${id}`);
      dispatch(actions.loadProductSuccess(null));
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(actions.loadProductFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const clearCurrentProduct = async () => {
    dispatch(actions.clearCurrentProduct());
  };

  const createProductFromParent = async (parentId: string) => {
    dispatch(actions.saveProductStart());
    let response;

    try {
      response = await axios.post<Product>(`${BASE_URL}/from-parent`, parentId);

      dispatch(actions.createProductFromParentSuccess(response.data));

      dispatch(
        notificationsActions.showNotification({
          title: "Éxito",
          message: "Producto creado con éxito a partir de Producto padre",
          status: NOTIFICATION_STATUS.SUCCESS,
          vertical: VERTICAL_POSITIONS.BOTTOM,
          horizontal: HORIZONTAL_POSITIONS.CENTER,
        })
      );

      return response.data.id;
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(actions.saveProductFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
    }
  };

  const fetchProductChildren = async (parentId: string) => {
    dispatch(actions.loadChildrenStart());
    try {
      const response = await axios.get<Product[] | null>(
        `${BASE_URL}/children`,
        {
          params: {
            parentId,
          },
        }
      );

      dispatch(
        actions.loadChildrenSuccess({
          children: response.data,
          parentId,
        })
      );

      return response.data ? (response.data as IAccessItem[]) : [];
    } catch (error: any) {
      const errorMsg = error.response?.data?.title || error.message;
      dispatch(actions.loadChildrenFailure(errorMsg));
      dispatch(notificationsActions.showErrorNotification(errorMsg));
      return [];
    }
  };

  return {
    fetchProducts,
    fetchProductById,
    saveProduct,
    updateProductAndChildren,
    deleteProductById,
    clearCurrentProduct,
    createProductFromParent,
    fetchProductChildren,
  };
};

export { useActions };
export default useActions;
