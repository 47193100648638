import { RootState } from "store/index";
import { createSelector } from "@reduxjs/toolkit";
import { useAppSelector } from "store/hooks";
import { Location } from "locations/types";

const selectLocations = (state: RootState) => state.locations.locations;

const selectActiveLocations = createSelector(
  [selectLocations],
  (locations: Location[] | null) =>
    locations?.filter((location) => location.isActive === true) || []
);

export const useActiveLocations = () => useAppSelector(selectActiveLocations);
