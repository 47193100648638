import portalStyles from "shared/styles/Portal.module.scss";
import { Button } from "@mui/material";
import { ProductLocationTxDetail } from "inventory/product-location-tx/types";
import BackdropPortal from "shared/components/portals/Backdrop";
import ReactDOM from "react-dom";
import { Product } from "inventory/product/types";
import MainList from "shared/components/main-list/MainList";
import { IAccessItem } from "shared/components/access-item/AccessItem";
import { ProductFooter } from "shared/components/access-item/access-item-templates/Footers";
import ConfirmQuantityPortal from "shared/components/confirm-quantity-portal/ConfirmQuantityPortal";
import { useState } from "react";
import { COMPONENTS } from "configuration/types";

interface FormProps {
  handleSubmit: (entityObj: ProductLocationTxDetail) => void;
  handleCancel: () => void;
  formData: ProductLocationTxDetail | null;
  products: Product[] | null;
}

const onSearch = (term: string, data: any) => {
  const searchTerm = term.toUpperCase();
  const result = data?.filter((item: any) => {
    if (item.description?.includes(searchTerm)) {
      return item;
    }
    if (item.brand?.name?.includes(searchTerm)) {
      return item;
    }
    if (item.parent?.name?.includes(searchTerm)) {
      return item;
    }
  });

  return result;
};

const TxDetailFormPortal = ({
  handleSubmit,
  handleCancel,
  products,
}: FormProps) => {
  const [showConfirmQuantityPortal, setShowConfirmQuantityPortal] =
    useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const handleQuantityUpdate = (quantity: number) => {
    const txDetail: ProductLocationTxDetail = {
      id: null,
      txId: "",
      productId: selectedProduct?.id || "",
      product: selectedProduct,
      quantity: quantity,
    };

    handleSubmit(txDetail);
  };
  return (
    <>
      <BackdropPortal>
        {ReactDOM.createPortal(
          <>
            {showConfirmQuantityPortal && (
              <ConfirmQuantityPortal
                handleSubmit={handleQuantityUpdate}
                handleCancel={() => setShowConfirmQuantityPortal(false)}
                portalContainerId="secondary-portal"
              ></ConfirmQuantityPortal>
            )}

            <div className={portalStyles.portal_content}>
              <div id="secondary-portal"></div>
              <MainList
                data={products}
                loading={false}
                onItemClick={(item: IAccessItem) => {
                  setSelectedProduct(item as Product);
                  setShowConfirmQuantityPortal(true);
                }}
                onSearch={onSearch}
                gridViewFooter={ProductFooter}
                controlOwnerName={COMPONENTS.PRODUCT_LOCATION_TXS + "_DETAILS"}
              ></MainList>
              <Button
                color="primary"
                variant="contained"
                onClick={handleCancel}
                style={{ marginTop: "10px" }}
              >
                Cancelar
              </Button>
            </div>
          </>,
          document.getElementById("overlay-root") as HTMLElement
        )}
      </BackdropPortal>
    </>
  );
};

export default TxDetailFormPortal;
