import { createSlice } from "@reduxjs/toolkit";
import { ProductCategory } from "../types";

interface StateObject {
  productCategories: ProductCategory[] | null;
  loadingProductCategories: boolean;
  errorProductCategories: string | null;
  productCategory: ProductCategory | null;
  loadingProductCategory: boolean;
  errorProductCategory: string | null;
}

const initialState: StateObject = {
  productCategories: null,
  loadingProductCategories: false,
  errorProductCategories: null,
  productCategory: null,
  loadingProductCategory: false,
  errorProductCategory: null,
};

const productCategoriesSlice = createSlice({
  name: "productCategories",
  initialState,
  reducers: {
    loadProductCategoriesStart(state) {
      state.loadingProductCategories = true;
      state.errorProductCategories = null;
    },
    loadProductCategoriesSuccess(state, action) {
      state.loadingProductCategories = false;
      state.errorProductCategories = null;
      state.productCategories = action.payload;
    },
    loadProductCategoriesFailure(state, action) {
      state.loadingProductCategories = false;
      state.errorProductCategories = action.payload;
    },
    loadProductCategoryStart(state) {
      state.loadingProductCategory = true;
      state.errorProductCategory = null;
    },
    loadProductCategorySuccess(state, action) {
      state.loadingProductCategory = false;
      state.errorProductCategory = null;
      state.productCategory = action.payload;
    },
    loadProductCategoryFailure(state, action) {
      state.loadingProductCategory = false;
      state.errorProductCategory = action.payload;
    },
    saveProductCategoryStart(state) {
      state.loadingProductCategory = true;
    },
    saveProductCategorySuccess(state, action) {
      state.loadingProductCategory = false;
      state.productCategory = action.payload;
      state.errorProductCategory = null;
    },
    saveProductCategoryFailure(state, action) {
      state.loadingProductCategory = false;
      state.productCategory = null;
      state.errorProductCategory = action.payload;
    },
    clearCurrentProductCategory(state) {
      state.productCategory = null;
      state.loadingProductCategory = false;
      state.errorProductCategory = null;
    },
  },
});

export const actions = productCategoriesSlice.actions;
export default productCategoriesSlice.reducer;
