import axios from "../../shared/services/axios";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { actions as authActions } from "../store/index";
import { useNavigate } from "react-router-dom";
import { AuthUser } from "../types";
import { RootState } from "../../store";

const useRefreshToken = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.auth.user);

  const refresh = async () => {
    try {
      console.log("trying to get refresh token");
      dispatch(authActions.refreshingTokenStart());
      const response = await axios.post(
        "/auth/refresh-token",
        {},
        {
          withCredentials: true,
        }
      );

      if (response.data.token) {
        console.log("UPDATING TOKEN IN STORE");
        const { defaultOrganization, defaultLocation, ...rest } = response.data;

        const userData: AuthUser = {
          ...rest,
          currentOrganization: user?.currentOrganization ?? defaultOrganization,
          currentLocation: user?.currentLocation ?? defaultLocation,
        };
        dispatch(authActions.refreshingTokenSuccess(userData));
        return { token: response.data.token, userData };
      } else {
        throw Error("Server did not provide Access Token");
      }
    } catch (error: any) {
      const message = error.response?.data?.title || error.message;
      dispatch(authActions.refreshingTokenFailure(message));
      navigate("/auth/login");
    }
  };
  return refresh;
};

export default useRefreshToken;
