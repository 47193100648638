import {
  Button,
  Fab,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import AccessItem, { IAccessItem } from "../access-item/AccessItem";
import styles from "./MainList.module.scss";
import {
  AccountTreeTwoTone,
  AddTwoTone,
  ClearTwoTone,
  DeselectTwoTone,
  GridViewTwoTone,
  MoreVertTwoTone,
  SelectAllTwoTone,
  ViewListTwoTone,
} from "@mui/icons-material";
import LoadingPage from "../loading/LoadingPage";
import { useEffect, useState } from "react";
import { IHiearchyMember } from "shared/types/ihierarchy-member";
import { useNavigate } from "react-router-dom";
import ListViewItem from "../list-item/ListViewItem";

export interface MainListProps extends React.PropsWithChildren {
  data: IAccessItem[] | null;
  loading: boolean;
  selectionMode?: "single" | "multiple";
  viewMode?: "list" | "grid";
  itemImagePath?: string;
  itemTitlePath?: string;
  gridViewFooter?: any;
  listViewFooter?: any;
  onItemClick: (e: IAccessItem) => void;
  onAddClick?: () => void;
  onSelectionChange?: (items: IAccessItem[]) => void;
  onSearch?: (term: string, data: IAccessItem[]) => IAccessItem[] | [];
  onFetchDataInHierarchy?: (parentId?: string) => Promise<IAccessItem[] | []>;
  parentPath?: string;
  controlOwnerName: string;
}

const MainList = ({
  data,
  loading,
  selectionMode,
  viewMode,
  itemImagePath,
  itemTitlePath,
  gridViewFooter,
  listViewFooter,
  onItemClick,
  onAddClick,
  onSelectionChange,
  onSearch,
  onFetchDataInHierarchy,
  parentPath,
  controlOwnerName,
}: MainListProps) => {
  const [items, setItems] = useState<IAccessItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<IAccessItem[]>([]);
  const [currentParent, setCurrentParent] = useState<IHiearchyMember | null>(
    null
  );

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentViewMode, setCurrentViewMode] = useState(viewMode);
  const isOptionsMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    if (data) {
      search(sessionStorage.getItem(controlOwnerName) || null);
      setSelectedItems([]);
    } else {
      setSelectedItems([]);
      setItems([]);
    }
  }, [data]);

  const search = (term: string | null) => {
    sessionStorage.setItem(controlOwnerName, term ?? "");

    if (term && onSearch && data) {
      // Clear selected items when searching
      if (selectedItems.length > 0) {
        setSelectedItems([]);
        onSelectionChange && onSelectionChange([]);
      }
      const result = onSearch(term, data);
      setItems(result || []);
    } else {
      setItems(data || []);
    }
  };

  const handleOptionsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOptionsMenuClose = () => {
    setAnchorEl(null);
  };

  const renderFooter = (item: IAccessItem) => {
    if (currentViewMode === "grid") {
      if (typeof gridViewFooter === "function") {
        return () => <>{gridViewFooter(item)}</>;
      } else {
        return typeof listViewFooter === "function"
          ? () => <>{listViewFooter(item)}</>
          : null;
      }
    }
    if (currentViewMode === "list") {
      if (typeof listViewFooter === "function") {
        return () => <>{listViewFooter(item)}</>;
      } else {
        return typeof gridViewFooter === "function"
          ? () => <>{gridViewFooter(item)}</>
          : null;
      }
    }
    return null;
  };

  const handleClickInterceptor = (item: IAccessItem) => {
    if (item.isParent && onFetchDataInHierarchy) {
      setCurrentParent(item);
      onFetchDataInHierarchy(item.id!).then((result: any) => {
        if (result.length > 0) {
          setItems(result);
        }
      });

      return;
    }

    if (selectionMode === "multiple") {
      const isSelectedItem = item.selected;
      let updatedSelection: IAccessItem[];

      if (item.selected) {
        updatedSelection = selectedItems.filter((i) => i.id !== item.id);
      } else {
        updatedSelection = [...(selectedItems ?? []), item];
      }
      setSelectedItems(updatedSelection);
      setItems(
        items?.map((i) =>
          i.id === item.id ? { ...i, selected: !isSelectedItem } : i
        ) ?? []
      );
      onSelectionChange && onSelectionChange(updatedSelection);
    } else {
      onItemClick(item);
    }
  };

  if (loading) {
    return <LoadingPage></LoadingPage>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.action_bar}>
        {onAddClick && (
          <div className={styles.action_button}>
            <Fab color="primary" aria-label="add" onClick={onAddClick}>
              <AddTwoTone></AddTwoTone>
            </Fab>
          </div>
        )}
      </div>
      <div className={styles.tool_bar}>
        {currentParent && (
          <Button
            className={styles.parent_button}
            variant="text"
            startIcon={<AccountTreeTwoTone />}
            onClick={() => {
              if (parentPath) {
                navigate(`/${parentPath}/${currentParent?.id}`);
              }
            }}
          >
            Ir a Padre
          </Button>
        )}

        {onSearch && (
          <TextField
            label="Buscar"
            variant="standard"
            className={styles.search_input}
            value={sessionStorage.getItem(controlOwnerName) || ""}
            onChange={(e) => {
              search(e.target.value);
            }}
            InputProps={{
              endAdornment: sessionStorage.getItem(controlOwnerName) && (
                <IconButton
                  onClick={() => {
                    sessionStorage.removeItem(controlOwnerName);
                    search(null);
                  }}
                >
                  <ClearTwoTone></ClearTwoTone>
                </IconButton>
              ),
            }}
          />
        )}
        <IconButton
          id="main-list-context-menu"
          aria-controls={
            isOptionsMenuOpen ? "main-list-context-menu" : undefined
          }
          aria-haspopup="true"
          aria-expanded={isOptionsMenuOpen ? "true" : undefined}
          onClick={handleOptionsMenuClick}
        >
          <MoreVertTwoTone></MoreVertTwoTone>
        </IconButton>
        <Menu
          id="main-list-context-menu"
          anchorEl={anchorEl}
          open={isOptionsMenuOpen}
          onClose={handleOptionsMenuClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          style={{ zIndex: 99999 }}
        >
          <MenuItem
            onClick={() => {
              setCurrentViewMode((prev) => (prev === "grid" ? "list" : "grid"));
              handleOptionsMenuClose();
            }}
          >
            <ListItemIcon>
              {currentViewMode === "list" ? (
                <GridViewTwoTone fontSize="small" />
              ) : (
                <ViewListTwoTone fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText>Cambiar Vista</ListItemText>
          </MenuItem>

          {selectionMode === "multiple" && selectedItems.length > 0 && (
            <MenuItem
              onClick={() => {
                setSelectedItems([]);
                setItems(
                  items?.map((i) =>
                    selectedItems.find((s) => s.id === i.id)
                      ? { ...i, selected: false }
                      : i
                  ) ?? []
                );
                onSelectionChange && onSelectionChange([]);
              }}
            >
              <ListItemIcon>
                <DeselectTwoTone fontSize="small" />
              </ListItemIcon>
              <ListItemText>Deseleccionar todo</ListItemText>
            </MenuItem>
          )}
          {selectionMode === "multiple" && selectedItems.length === 0 && (
            <MenuItem
              onClick={() => {
                const updatedSelection =
                  items?.map((i) => ({ ...i, selected: true })) ?? [];
                setSelectedItems(updatedSelection);
                setItems(updatedSelection);
                onSelectionChange && onSelectionChange(updatedSelection);
              }}
            >
              <ListItemIcon>
                <SelectAllTwoTone fontSize="small" />
              </ListItemIcon>
              <ListItemText>Seleccionar todo</ListItemText>
            </MenuItem>
          )}
        </Menu>
      </div>

      {currentViewMode === "list" ? (
        <ul className={styles.list_view}>
          {items?.map((e) => (
            <ListViewItem
              key={e.id}
              item={e}
              handleClick={handleClickInterceptor}
              imagePath={itemImagePath}
              titlePath={itemTitlePath}
              footer={renderFooter(e)}
            ></ListViewItem>
          ))}
        </ul>
      ) : (
        <ul className={styles.image_gallery}>
          {items?.map((e) => (
            <AccessItem
              key={e.id}
              item={e}
              handleClick={handleClickInterceptor}
              imagePath={itemImagePath}
              titlePath={itemTitlePath}
              footer={renderFooter(e)}
            ></AccessItem>
          ))}
        </ul>
      )}
    </div>
  );
};

MainList.defaultProps = {
  selectionMode: "single",
  viewMode: "list",
};

export default MainList;
