import { Navigate, Outlet, useLocation } from "react-router-dom";
import { PERMISSIONS } from "configuration/types";
import useAuthUser from "auth/hooks/useAuthUser";
import { useAppSelector } from "../../../store/hooks";
import { RootState } from "store";
import { useEffect, useState } from "react";
import LoadingPage from "shared/components/loading/LoadingPage";

interface Props {
  componentName: string;
  requiredPermissions?: PERMISSIONS[];
}

const GrantAccess = ({ componentName, requiredPermissions }: Props) => {
  const user = useAuthUser();
  const location = useLocation();

  return !!user ? (
    hasAccess() ? (
      <Outlet></Outlet>
    ) : (
      <Navigate
        to="/unauthorized"
        state={{ from: location }}
        replace
      ></Navigate>
    )
  ) : (
    <LoadingPage message="Autenticando..."></LoadingPage>
  );

  function hasAccess() {
    return true;
  }
};

export default GrantAccess;
