import ReactDOM from "react-dom";
import styles from "./Backdrop.module.scss";

const BackdropPortal = ({ children }: any) => {
  return ReactDOM.createPortal(
    <div className={styles.backdrop_portal}>{children}</div>,
    document.getElementById("backdrop-root") as HTMLElement
  );
};

export default BackdropPortal;
