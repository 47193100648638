import accessItemStyles from "shared/components/access-item/AccessItem.module.scss";
const ProductFooter = (item: any) => {
  return (
    <div className={accessItemStyles.footer_item}>
      <span>
        <strong>{item.description}</strong>
      </span>
    </div>
  );
};

const ItemWithNameFooter = (item: any) => {
  return (
    <div className={accessItemStyles.footer_item}>
      <span>
        <strong>{item.name}</strong>
      </span>
    </div>
  );
};

export { ProductFooter, ItemWithNameFooter };
