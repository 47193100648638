import { useAppSelector } from "store/hooks";
import { useEffect, useState } from "react";
import { RootState } from "store";
import { useNavigate } from "react-router-dom";
import useActions from "locations/store/useActions";
import CustomDataGrid from "shared/components/custom-data-grid/CustomDataGrid";
import useAuthUser from "auth/hooks/useAuthUser";
import { CustomDataGridColumn } from "shared/components/custom-data-grid/types";
import { Identity } from "shared/types/identity";

const columns: CustomDataGridColumn[] = [
  {
    field: "name",
    caption: "Ubicación",
    dataType: "string",
  },
  {
    field: "typeId",
    caption: "Tipo",
    dataType: "string",
    cellCustomTextFn(cellData) {
      switch (cellData.value) {
        case 1:
          return "TIENDA";
        case 2:
          return "BODEGA";
        default:
          return cellData.valueText;
      }
    },
  },
  {
    field: "isExternal",
    caption: "Externa",
    dataType: "boolean",
  },
  {
    field: "isActive",
    caption: "Activo",
    dataType: "boolean",
  },
  {
    field: "createdOn",
    caption: "Creado el",
    dataType: "date",
    visible: false,
  },
];

const LocationsListPage = () => {
  const navigate = useNavigate();
  const user = useAuthUser();

  const data = useAppSelector((state: RootState) => state.locations.locations);
  const loading = useAppSelector(
    (state: RootState) => state.locations.loadingLocations
  );

  const { fetchLocations, clearCurrentLocation } = useActions();

  useEffect(() => {
    if (user?.currentLocation?.id) {
      fetchLocations();
    }
  }, [user]);

  const handleAdd = () => {
    clearCurrentLocation().then(() => {
      navigate("create");
    });
  };

  const handleEdit = (obj: Identity) => {
    navigate(obj.id!);
  };

  return (
    <>
      <CustomDataGrid
        data={data}
        loading={loading}
        columns={columns}
        onAdd={handleAdd}
        onEdit={handleEdit}
        allowExporting
        controlName={"locations"}
        showColumnChooser={true}
      ></CustomDataGrid>
    </>
  );
};

export default LocationsListPage;
