import { useAppSelector } from "store/hooks";
import { useEffect } from "react";
import { RootState } from "store";
import { useNavigate } from "react-router-dom";
import useActions from "inventory/product/store/useActions";
import useAuthUser from "auth/hooks/useAuthUser";
import { Identity } from "shared/types/identity";
import MainList from "shared/components/main-list/MainList";
import { ProductFooter } from "shared/components/access-item/access-item-templates/Footers";
import { Product } from "inventory/product/types";
import { IAccessItem } from "shared/components/access-item/AccessItem";
import { COMPONENTS } from "configuration/types";

const ProductsListPage = () => {
  const navigate = useNavigate();
  const user = useAuthUser();

  const data = useAppSelector<Product[] | null>(
    (state: RootState) => state.products.products
  );

  const loading = useAppSelector(
    (state: RootState) => state.products.loadingProducts
  );

  const { fetchProducts, fetchProductChildren, clearCurrentProduct } =
    useActions();

  useEffect(() => {
    if (user?.currentLocation?.id) {
      fetchProducts();
    }
  }, [user]);

  const handleAdd = () => {
    clearCurrentProduct().then(() => {
      navigate("create");
    });
  };

  const handleEdit = (obj: Identity) => {
    navigate(obj.id!);
  };

  const fetchItemsInHierarchy = async (parentId?: string) => {
    return (await fetchProducts(parentId)) as IAccessItem[];
  };

  const onSearch = (term: string, data: any) => {
    const searchTerm = term.toUpperCase();
    const result = data?.filter((item: any) => {
      if (item.description?.includes(searchTerm)) {
        return item;
      }
      if (item.brand?.name?.includes(searchTerm)) {
        return item;
      }
      if (item.parent?.name?.includes(searchTerm)) {
        return item;
      }
    });

    return result;
  };

  return (
    <>
      <MainList
        data={data}
        loading={loading}
        itemImagePath="imageUrl"
        itemTitlePath="description"
        selectionMode="single"
        onItemClick={handleEdit}
        onAddClick={handleAdd}
        gridViewFooter={ProductFooter}
        onSearch={onSearch}
        onFetchDataInHierarchy={fetchItemsInHierarchy}
        parentPath="products"
        controlOwnerName={COMPONENTS.PRODUCTS}
      ></MainList>
    </>
  );
};

export default ProductsListPage;
