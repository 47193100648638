import styles from "./ProductLocationMovementsReportPage.module.scss";
import useAuthUser from "auth/hooks/useAuthUser";
import { COMPONENTS } from "configuration/types";
import useActions from "reports/store/useActions";
import CustomDataGrid from "shared/components/custom-data-grid/CustomDataGrid";
import {
  CustomDataGridColumn,
  CustomSummaryGridColumn,
} from "shared/components/custom-data-grid/types";
import StartEndDatesFilter from "shared/components/start-end-dates-filter/StartEndDatesFilter";
import { useAppSelector } from "store/hooks";

const columns: CustomDataGridColumn[] = [
  {
    field: "txDate",
    caption: "Fecha",
    dataType: "date",
    format: "dd/MM/yyyy",
  },
  {
    field: "involvedLocationName",
    caption: "Provee",
  },
  {
    field: "productDescription",
    caption: "Producto",
  },
  {
    field: "totalQuantityReceived",
    caption: "Recibido",
  },
  {
    field: "totalQuantityReturned",
    caption: "Devuelto",
  },
  {
    field: "totalQuantitySold",
    caption: "Vendido",
  },
];

const summaryColumns: CustomSummaryGridColumn[] = [
  {
    column: "totalQuantityReceived",
    summaryType: "sum",
    customTextFn: (i) => `Total: ${i.value}`,
  },
  {
    column: "totalQuantityReturned",
    summaryType: "sum",
    customTextFn: (i) => `Total: ${i.value}`,
  },
  {
    column: "totalQuantitySold",
    summaryType: "sum",
    customTextFn: (i) => `Total: ${i.value}`,
  },
];

const ProductLocationMovementsReportPage = () => {
  const user = useAuthUser();
  const { fetchInOutProductsReportData } = useActions();

  const data = useAppSelector(
    (state) => state.reports.locationInOutProductsReportData
  );

  const loading = useAppSelector((state) => state.reports.loadingReport);

  const fetchReportData = (startDate: Date, endDate: Date) => {
    if (user?.currentLocation?.id) {
      fetchInOutProductsReportData(user.currentLocation.id, startDate, endDate);
    } else {
      alert("Could not determined Location");
    }
  };

  return (
    <div className={styles.main_container}>
      <StartEndDatesFilter handleSubmit={fetchReportData} />
      <CustomDataGrid
        controlName={COMPONENTS.REPORTS + "_LOCATION_IN_OUT_PRODUCTS"}
        loading={loading}
        data={data}
        columns={columns}
        summaryTotalColumns={summaryColumns}
        keyField={["txDate", "involvedLocationName", "productDescription"]}
      ></CustomDataGrid>
    </div>
  );
};

export default ProductLocationMovementsReportPage;
