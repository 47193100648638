export const FormDataFromObject = (obj: any) => {
  const formData = new FormData();
  if (obj != null) {
    Object.keys(obj).forEach((k) => {
      if (obj[k] instanceof File) {
        formData.append(k, obj[k]);
      } else if (obj[k] != null) {
        formData.append(k, obj[k].toString());
      }
    });
  }
  return formData;
};

export const getPropertyValueFromPath = (obj: any, path: string) => {
  const segments = path.split(".");
  let current = obj;
  for (const segment of segments) {
    if (current && typeof current === "object" && segment in current) {
      current = current[segment];
    } else {
      return undefined;
    }
  }
  return current;
};
