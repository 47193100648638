import styles from "./OrganizationSelector.module.scss";
import { Organization } from "../types";
import useAuthActions from "../../auth/hooks/useAuthActions";

export interface OrganizationSelectorProps {
  organizations: Organization[] | null;
  currentOrganization: Organization;
}

const OrganizationSelector = ({
  organizations,
  currentOrganization,
}: OrganizationSelectorProps) => {
  const { updateUserCurrentOrganization } = useAuthActions();
  return (
    organizations && (
      <div className={styles.main_content}>
        {organizations.map((o) => {
          return (
            <div
              onClick={() => updateUserCurrentOrganization(o)}
              className={`${styles.avatar_row} ${
                currentOrganization?.id === o.id
                  ? styles.current_organization_title
                  : ""
              }`}
              key={o.id}
            >
              <img src={o.imageUrl}></img>
              <h5>{o.name}</h5>
            </div>
          );
        })}
      </div>
    )
  );
};

export default OrganizationSelector;
