import { COMPONENTS } from "configuration/types";
import { IAccessItem } from "shared/components/access-item/AccessItem";
import MainList from "shared/components/main-list/MainList";
import accessItemStyles from "shared/components/access-item/AccessItem.module.scss";
import { useNavigate } from "react-router-dom";

const data: IAccessItem[] = [
  {
    id: "product-location-movements",
    name: "Entradas/Salidas",
    description: "Entradas y salidas de productos",
  },
];

const ReportsHomePage = () => {
  const navigate = useNavigate();

  const handleClick = (item: IAccessItem) => {
    navigate(item.id!);
  };

  return (
    <div>
      <MainList
        data={data}
        loading={false}
        onItemClick={handleClick}
        gridViewFooter={(item: any) => (
          <>
            <div className={accessItemStyles.footer_item}>
              <span>
                <strong>{item.name}</strong>
              </span>
            </div>
          </>
        )}
        controlOwnerName={COMPONENTS.REPORTS}
      ></MainList>
    </div>
  );
};

export default ReportsHomePage;
