import PositionedSnackbar from "../../../../notifications/snackbar/Snackbar";
import styles from "../../../../shared/styles/Page.module.scss";
import BrandForm from "../../componentes/form/BrandForm";
import { useAppSelector } from "../../../../store/hooks";
import { Brand } from "../../types";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { RootState } from "../../../../store";
import useBrandActions from "../../store/useActions";

const BrandPage = () => {
  const params = useParams();
  const formData = useAppSelector((state: RootState) => state.brands.brand);
  const loading = useAppSelector(
    (state: RootState) => state.brands.loadingBrand
  );
  const notification = useAppSelector(
    (state) => state.notifications.notification
  );

  const { fetchBrandById, saveBrand } = useBrandActions();

  useEffect(() => {
    const id = params.id;
    if (id) {
      fetchBrandById(id);
    }
  }, []);

  const navigate = useNavigate();

  const onSave = (obj: Brand, image: File | null) => {
    saveBrand(obj, image).then(() => {
      navigate("/brands");
    });
  };
  return (
    <div className={styles.page_content}>
      <BrandForm
        formData={formData}
        handleSubmit={onSave}
        loading={loading}
        handleCancel={() => {
          navigate("/brands");
        }}
      ></BrandForm>
      {notification && (
        <PositionedSnackbar
          {...{ ...notification, open: !!notification }}
        ></PositionedSnackbar>
      )}
    </div>
  );
};

export default BrandPage;
