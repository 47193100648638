import { Outlet } from "react-router-dom";

const PersistLogin = () => {
  return (
    <>
      <Outlet></Outlet>
    </>
  );
};

export default PersistLogin;
