import { useFormik } from "formik";
import sharedStyles from "shared/styles/Form.module.scss";
import {
  Autocomplete,
  Button,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import LoadingPage from "shared/components/loading/LoadingPage";
import { Product } from "inventory/product/types";
import useAuthUser from "auth/hooks/useAuthUser";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ProductCategory } from "inventory/product-category/types";
import { Brand } from "inventory/brand/types";
import ImagePresenter from "shared/components/image-presenter/ImagePresenter";
import { ProductSchema } from "inventory/product/validations";
import { AccountTreeRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
dayjs.extend(utc);

interface FormProps {
  handleSubmit: (entityObj: Product, image: File | null) => void;
  handleCancel: () => void;
  handleCreateFromProduct: () => void;
  formData: Product | null;
  loading: boolean;
  categories: ProductCategory[] | null;
  brands: Brand[] | null;
}

const ProductForm = ({
  handleSubmit,
  handleCancel,
  handleCreateFromProduct,
  formData,
  loading,
  categories,
  brands,
}: FormProps) => {
  const user = useAuthUser();
  const navigate = useNavigate();
  const formik = useFormik<Product>({
    initialValues: formData ?? {
      id: "",
      parentId: "",
      productCategoryId: "",
      brandId: "",
      referenceCode: "",
      description: "",
      cost: 0,
      minSellPrice: 0,
      imageUrl: "",
      isActive: true,
      isParent: false,
      children: [],
    },
    enableReinitialize: true,
    validationSchema: ProductSchema,
    onSubmit: async (values) => {
      handleSubmit(values, image);
    },
  });

  const [image, setImage] = useState<File | null>(null);

  useEffect(() => {
    if (
      !formik.values.id &&
      formik.values.brandId &&
      formik.values.productCategoryId
    ) {
      const brand = brands?.find((i) => i.id === formik.values.brandId);
      const category = categories?.find(
        (i) => i.id === formik.values.productCategoryId
      );

      formik.setFieldValue("description", `${category?.name} - ${brand?.name}`);
    }
  }, [formik.values.brandId, formik.values.productCategoryId]);

  if (loading) {
    return <LoadingPage></LoadingPage>;
  }
  return (
    <>
      <div>
        {formData?.parentId && (
          <h4 style={{ margin: "auto" }}>
            Producto padre: {formData?.parent?.description}
          </h4>
        )}
      </div>
      <div className={sharedStyles.form_content}>
        <form onSubmit={formik.handleSubmit}>
          <ImagePresenter
            imageUrl={formData?.imageUrl ?? null}
            onChange={(e: File | null) => {
              setImage(e);
            }}
          ></ImagePresenter>

          <div className={sharedStyles.form_section_row}>
            <div className={sharedStyles.form_row}>
              <Autocomplete
                disabled={!!formData?.parentId}
                className={sharedStyles.input_field}
                id="productCategoryId"
                blurOnSelect
                options={categories || []}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                value={
                  categories?.find(
                    (o) => o.id === formik.values.productCategoryId
                  ) || null
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                  formik.setFieldValue("productCategoryId", value?.id || null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={
                      formik.touched.productCategoryId &&
                      Boolean(formik.errors.productCategoryId)
                    }
                    helperText={
                      formik.touched.productCategoryId &&
                      formik.errors.productCategoryId
                    }
                    label="Categoría"
                    variant="outlined"
                  />
                )}
              />
            </div>
            <div className={sharedStyles.form_row}>
              <Autocomplete
                className={sharedStyles.input_field}
                id="brandId"
                disabled={!!formData?.parentId}
                blurOnSelect
                options={brands || []}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                value={
                  brands?.find((o) => o.id === formik.values.brandId) || null
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                  formik.setFieldValue("brandId", value?.id || null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={formik.touched.brandId && !!formik.errors.brandId}
                    helperText={formik.touched.brandId && formik.errors.brandId}
                    label="Marca"
                    variant="outlined"
                  />
                )}
              />
            </div>
          </div>
          <div className={sharedStyles.form_section_row}>
            <div className={sharedStyles.form_row}>
              <TextField
                className={sharedStyles.input_field}
                type="string"
                id="referenceCode"
                name="referenceCode"
                label="Código"
                autoComplete="no"
                value={formik.values.referenceCode || ""}
                onChange={(e) => {
                  formik.setFieldValue(
                    "referenceCode",
                    e.target.value.trimStart()
                  );
                }}
                error={
                  formik.touched.referenceCode && !!formik.errors.referenceCode
                }
                helperText={
                  formik.touched.referenceCode && formik.errors.referenceCode
                }
                InputLabelProps={{
                  shrink: formik.values.referenceCode != null,
                }}
              ></TextField>
            </div>
            <div className={sharedStyles.form_row}>
              <TextField
                className={sharedStyles.input_field}
                type="string"
                id="description"
                name="description"
                label="Descripción"
                autoComplete="no"
                value={formik.values.description || ""}
                onChange={(e) => {
                  formik.setFieldValue(
                    "description",
                    e.target.value.trimStart()
                  );
                }}
                error={
                  formik.touched.description && !!formik.errors.description
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
                InputLabelProps={{
                  shrink: formik.values.description != null,
                }}
                sx={{ width: "100%" }}
              ></TextField>
            </div>
          </div>
          <div className={sharedStyles.form_section_row}>
            <div className={sharedStyles.form_row}>
              <FormControlLabel
                control={
                  <Switch
                    id="isActive"
                    name="isActive"
                    checked={formik.values.isActive}
                    onChange={formik.handleChange}
                  />
                }
                label="Activo"
                labelPlacement="start"
              />
            </div>
            <div className={sharedStyles.form_row}></div>
          </div>

          <div className={sharedStyles.buttons_bar}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleCancel}
            >
              Cancelar
            </Button>
            <Button color="primary" variant="contained" fullWidth type="submit">
              Guardar
            </Button>

            {formData?.parentId === null && (
              <Button
                color="primary"
                variant="contained"
                onClick={handleCreateFromProduct}
              >
                <AccountTreeRounded></AccountTreeRounded> Variante
              </Button>
            )}
            {formData?.parentId && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  navigate(`/products/${formData.parentId}`);
                }}
              >
                <AccountTreeRounded></AccountTreeRounded> Ir a padre
              </Button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default ProductForm;
