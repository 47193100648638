import styles from "./MainLayout.module.scss";
import { useAppSelector } from "../../store/hooks";
import PositionedSnackbar from "../../notifications/snackbar/Snackbar";
import PersistentDrawerLeft from "../drawer/Drawer";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useActions from "locations/store/useActions";

const MainLayout = () => {
  const notification = useAppSelector(
    (state) => state.notifications.notification
  );

  const { fetchLocations } = useActions();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/") {
      navigate("product-location-txs");
    }
  }, [location]);

  useEffect(() => {
    fetchLocations();
  }, []);

  return (
    <div className={styles.main_container}>
      <PersistentDrawerLeft></PersistentDrawerLeft>

      {notification && (
        <PositionedSnackbar
          {...{ ...notification, open: !!notification }}
        ></PositionedSnackbar>
      )}
    </div>
  );
};

export default MainLayout;
