import { useFormik } from "formik";
import sharedStyles from "shared/styles/Form.module.scss";
import {
  Autocomplete,
  Button,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import LoadingPage from "shared/components/loading/LoadingPage";
import { Location } from "locations/types";
import useAuthUser from "auth/hooks/useAuthUser";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { LocationSchema } from "locations/validations";
import { CatalogItem } from "shared/types/catalog";
dayjs.extend(utc);

interface FormProps {
  handleSubmit: (entityObj: Location, image: File | null) => void;
  handleCancel: () => void;
  formData: Location | null;
  loading: boolean;
  locationTypes: CatalogItem[] | null;
}

const LocationForm = ({
  handleSubmit,
  handleCancel,
  formData,
  loading,
  locationTypes,
}: FormProps) => {
  const user = useAuthUser();

  const formik = useFormik<Location>({
    initialValues: formData ?? {
      id: "",
      name: "",
      typeId: 0,
      isExternal: false,
      isActive: true,
    },
    enableReinitialize: true,
    validationSchema: LocationSchema,
    onSubmit: async (values) => {
      handleSubmit(values, image);
    },
  });

  const [image, setImage] = useState<File | null>(null);

  if (loading) {
    return <LoadingPage></LoadingPage>;
  }

  return (
    <>
      <div className={sharedStyles.form_content}>
        <form onSubmit={formik.handleSubmit}>
          <div className={sharedStyles.form_section_row}>
            <div className={sharedStyles.form_row}>
              <TextField
                className={sharedStyles.input_field}
                type="string"
                id="name"
                name="name"
                label="Nombre"
                autoComplete="no"
                value={formik.values.name || ""}
                onChange={(e) => {
                  formik.setFieldValue("name", e.target.value.trimStart());
                }}
                error={formik.touched.name && !!formik.errors.name}
                helperText={formik.touched.name && formik.errors.name}
                InputLabelProps={{
                  shrink: formik.values.name != null,
                }}
              ></TextField>
            </div>
          </div>
          <div className={sharedStyles.form_section_row}>
            <div className={sharedStyles.form_row}>
              <Autocomplete
                className={sharedStyles.input_field}
                id="typeId"
                blurOnSelect
                options={locationTypes || []}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                value={
                  locationTypes?.find((o) => o.id === formik.values.typeId) ||
                  null
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                  formik.setFieldValue("typeId", value?.id || null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={
                      formik.touched.typeId && Boolean(formik.errors.typeId)
                    }
                    helperText={formik.touched.typeId && formik.errors.typeId}
                    label="Tipo"
                    variant="outlined"
                  />
                )}
              />
            </div>
          </div>
          <div className={sharedStyles.form_section_row}>
            <div className={sharedStyles.form_row}>
              <FormControlLabel
                control={
                  <Switch
                    id="isActive"
                    name="isActive"
                    checked={formik.values.isActive}
                    onChange={formik.handleChange}
                  />
                }
                label="Activo"
                labelPlacement="start"
              />
            </div>
            <div className={sharedStyles.form_row}>
              <FormControlLabel
                control={
                  <Switch
                    id="isExternal"
                    name="isExternal"
                    checked={formik.values.isExternal}
                    onChange={formik.handleChange}
                  />
                }
                label="Externa"
                labelPlacement="start"
              />
            </div>
          </div>

          <div className={sharedStyles.buttons_bar}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleCancel}
            >
              Cancelar
            </Button>
            <Button color="primary" variant="contained" fullWidth type="submit">
              Guardar
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default LocationForm;
