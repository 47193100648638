import axios from "axios";

// export const setTokenToAxios = () => {
//   if (localStorage.token) {
//     axios.defaults.headers.common[
//       "Authorization"
//     ] = `Bearer ${localStorage.getItem("token")}`;
//   } else {
//     delete axios.defaults.headers.common["Authorization"];
//   }
// };

// export const removeTokenFromAxios = () => {
//   delete axios.defaults.headers.common["Authorization"];
// };

// export const setBaseUrlAxios = () => {
//   axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// };

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

export const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
