import { Brand } from "inventory/brand/types";
import { ProductCategory } from "inventory/product-category/types";
import { IAccessItem } from "shared/components/access-item/AccessItem";
import { LinkTabProps } from "shared/navigation-tab/components/link-tab/LinkTab";

export interface Product extends IAccessItem {
  parentId?: string;
  parent?: Product;
  productCategoryId: string;
  productCategory?: ProductCategory | null;
  brandId: string;
  brand?: Brand | null;
  referenceCode: string;
  description: string;
  cost: number;
  minSellPrice: number;
  imageUrl: string;
  isActive: boolean;
  isParent: boolean;
  children: Product[];
}

export interface ChildProductPropertiesToUpdate {
  productCategoryId: boolean;
  brandId: boolean;
  referenceCode: boolean;
  description: boolean;
  cost: boolean;
  minSellPrice: boolean;
  imageUrl: boolean;
  isActive: boolean;
}

export const ProductPageNavTabs: LinkTabProps[] = [
  {
    label: "General",
    href: "",
  },
  {
    label: "Variantes",
    href: "children",
  },
];
