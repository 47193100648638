import portalStyles from "shared/styles/Portal.module.scss";
import sharedStyles from "shared/styles/Form.module.scss";
import ReactDOM from "react-dom";
import * as yup from "yup";
import BackdropPortal from "../portals/Backdrop";
import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";

interface FormProps {
  handleSubmit: (entityObj: any) => void;
  handleCancel: () => void;
}

export const formSchema = yup.object({
  quantity: yup
    .number()
    .required("Cantidad es requerida")
    .min(0, "Cantidad debe ser mayor a 0"),
});

const ConfirmQuantityForm = ({ handleSubmit, handleCancel }: FormProps) => {
  const formik = useFormik<{ quantity: number }>({
    initialValues: {
      quantity: 1,
    },
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      handleSubmit(values.quantity);
    },
  });

  return (
    <div className={portalStyles.form_content} style={{ zIndex: 10001 }}>
      <form onSubmit={formik.handleSubmit}>
        <h2 style={{ textAlign: "center" }}>Actualizar cantidad</h2>
        <div className={sharedStyles.form_section_row}>
          <div className={sharedStyles.form_row}>
            <TextField
              type="number"
              id="quantity"
              name="quantity"
              label="Cantidad"
              autoComplete="no"
              inputProps={{ min: 0, step: "any" }}
              value={formik.values.quantity}
              onChange={formik.handleChange}
              onFocus={(event) => {
                event.target.select();
              }}
              error={formik.touched.quantity && Boolean(formik.errors.quantity)}
              helperText={formik.touched.quantity && formik.errors.quantity}
              InputLabelProps={{ shrink: formik.values.quantity != null }}
            ></TextField>
          </div>
        </div>

        <div className={sharedStyles.buttons_bar}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleCancel}
          >
            Cancelar
          </Button>
          <Button color="primary" variant="contained" fullWidth type="submit">
            Agregar
          </Button>
        </div>
      </form>
    </div>
  );
};

interface PortalProps {
  handleSubmit: (entityObj: number) => void;
  handleCancel: () => void;
  portalContainerId?: string;
}

const ConfirmQuantityPortal = ({
  handleSubmit,
  handleCancel,
  portalContainerId,
}: PortalProps) => {
  return (
    <>
      <BackdropPortal>
        {ReactDOM.createPortal(
          <div className={portalStyles.portal_content}>
            <ConfirmQuantityForm
              handleSubmit={handleSubmit}
              handleCancel={handleCancel}
            ></ConfirmQuantityForm>
          </div>,
          document.getElementById(
            portalContainerId ?? "overlay-root"
          ) as HTMLElement
        )}
      </BackdropPortal>
    </>
  );
};

export default ConfirmQuantityPortal;
