import styles from "./LoginForm.module.scss";
import appIcon from "../../../assets/images/app_icon.png";
import { Button, CircularProgress, Paper, TextField } from "@mui/material";
import { Login } from "../types";
import { loginSchema } from "../validation";
import { useFormik } from "formik";

interface FormProps {
  handleSubmit: (valueToSave: Login) => void;
  isLoading: boolean;
}

const LoginForm = ({ handleSubmit, isLoading }: FormProps) => {
  const formik = useFormik<Login>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      handleSubmit(values);
    },
  });

  return (
    <div className={styles.main_content}>
      <h2>Welcome to Easy Retail</h2>
      <img src={appIcon}></img>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.form_row}>
          <TextField
            type="string"
            id="email"
            name="email"
            label="Email"
            FormHelperTextProps={{ style: { color: "blue" } }}
            value={formik.values.email.toLocaleLowerCase().trim()}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            InputLabelProps={{ shrink: formik.values.email != null }}
          ></TextField>
        </div>
        <div className={styles.form_row}>
          <TextField
            type="password"
            id="password"
            name="password"
            label="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputLabelProps={{ shrink: formik.values.password != null }}
          ></TextField>
        </div>
        <Button color="primary" variant="contained" fullWidth type="submit">
          {isLoading ? (
            <CircularProgress
              color={"inherit"}
              style={{ width: "24px", height: "24px" }}
            />
          ) : (
            "Login"
          )}
        </Button>
      </form>
    </div>
  );
};

export default LoginForm;
